import React from 'react'
import Link from '@material-ui/core/Link'


export default props =>
  <Link
    role="button"
    onClick={(e) => {
      e.stopPropagation()
      props.onClick && props.onClick(e)
    }}
    href={props.href}
    activeClassName={ + props.active && 'noneActiveClass' || 'page-layout__nav-item--active'} {...props}
    style={{
      ...props.style || {},
      textDecoration: 'none',
      cursor: 'pointer'
    }}>
          {props.children}
  </Link>
