import {FlagIcon} from "react-flag-kit";
import React from 'react'
import moment from 'moment'

import $lang from "Function/Langue";
import Constants from 'Constants'
import Data from 'Class/Data'
import User from "Class/User";

moment.locale('fr')

const FlagAvailable = ['AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'ER', 'ES', 'ET', 'EU', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GB-ENG', 'GB-NIR', 'GB-SCT', 'GB-WLS', 'GB-ZET', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LGBT', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SAMI', 'SB', 'SC', 'SD', 'SE', 'SE-JAM', 'SE-SKA', 'SE-VAS', 'SEFI', 'SG', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TORN', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'US', 'US-CA', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'WW', 'WW-AFR', 'WW-ASI', 'WW-AUS', 'WW-EUR', 'WW-NAM', 'WW-SAM', 'XK', 'YE', 'YT', 'ZA', 'ZM', 'ZW']

const isSubComponentAvailable = type_saisie_id => {
  return ((type_saisie_id === Constants.typeSaisie.ComboSimple || type_saisie_id === Constants.typeSaisie.ComboSimpleBool
      || type_saisie_id === Constants.typeSaisie.ComboSimpleEntier || type_saisie_id === Constants.typeSaisie.ComboSimpleSaisieLibre
      || type_saisie_id === Constants.typeSaisie.ComboMulti || type_saisie_id === Constants.typeSaisie.ComboMultiBool
      || type_saisie_id === Constants.typeSaisie.ComboMultiEntier || type_saisie_id === Constants.typeSaisie.ComboMultiSaisieLibre
      || type_saisie_id === Constants.typeSaisie.ComboMultiUpload || type_saisie_id === Constants.typeSaisie.ComboSimpleUpload))
}

const isJsonString = (str) => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return undefined
  }
}

const isJsonStringArray = (str) => {
  try {
    const json = JSON.parse(str)
    return json instanceof Array ? json : undefined
  } catch (e) {
    return undefined
  }
}

const isJsonStringObject = (str) => {
  try {
    const json = JSON.parse(str)
    return json instanceof Object ? json : undefined
  } catch (e) {
    return undefined
  }
}

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

const getRandomNumber = _ => {
  return (Math.random() + '').replace('.', '')
}

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const enumerateDaysBetweenDates = function (startDate, endDate) {
  var now = startDate.clone(), dates = []

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('YYYY-MM-DD'))
    now.add(1, 'days')
  }
  return dates
}

const reservationColor = (niveau, date) => {
  if (niveau > 0 && niveau <= 3) {
    return '#90ee90'
  } else if (niveau === 4) {
    return '#f08080'
  } else if (niveau === 5) {
    return '#FFA500'
  } else if (moment(date, 'YYYY-MM-DD HH:mm').isAfter(moment())) {
    return '#ffffe0'
  }

  return 'linear-gradient(to right, #ffffe0, #f08080)'
}

const generateArray = (max, start = false) => {
  let arr = Array(Math.trunc(max)).fill(0)

  if(start) {
    arr = arr.map((_, i) => start + i)
  }
  return arr
}

const searchJsonToRechercheArray = (search) => {
  const realSearch = Object.keys(search).filter(c =>
      search[c] !== ''
      && search[c] !== undefined
      && search[c] !== null
      && ((Array.isArray(search[c])
      && (search[c] || []).length > 0) || !Array.isArray(search[c]))).filter(champ => champ && champ[0] !== '?')

  const champToDelete = realSearch.filter(champ => champ && champ[0] === '%').map(champ => {
    if (search[champ.substring(1, champ.length)]) {
      search[search[champ] + champ.substring(1, champ.length)] = search[champ.substring(1, champ.length)]
      realSearch.push(search[champ] + champ.substring(1, champ.length))
    }
    return [champ.substring(1, champ.length), champ]
  })
  champToDelete.map(champ => {
    const iDelete1 = realSearch.indexOf(champ[0])
    if (iDelete1 !== -1) {
      realSearch.splice(iDelete1, 1)
    }
    const iDelete2 = realSearch.indexOf(champ[1])
    if (iDelete2 !== -1) {
      realSearch.splice(iDelete2, 1)
    }
  })
  return realSearch.filter(champ => champ && champ.slice(-1)[0] !== '_' && champ.split('_').slice(-1)[0] !== 'non').map(champ => {
    if(champ === '$or') {
      return {
        logique: 'OR',
        recherche: searchJsonToRechercheArray(search[champ])
      }
    } else if(champ === '$and') {
      return {
        logique: 'AND',
        recherche: searchJsonToRechercheArray(search[champ])
      }
    } else if (champ && champ[0] === '=' && typeof search[champ] === 'string') {
      return {
        champ: champ.substring(1, champ.length),
        valeur: search[champ],
        operation: typeof search[champ] === 'string' && 'like',
        join: search[champ + '_'],
        non: search[champ + '_non']
      }
    } else if (champ && champ[0] === '|' && Array.isArray(search[champ])) {
      return {
        champ: champ.substring(1, champ.length),
        valeur: search[champ],
        operation: 'between',
        join: search[champ + '_'],
        non: search[champ + '_non']
      }
    } else if (champ && champ[0] === '<') {
      return {
        champ: champ.substring(1, champ.length),
        valeur: search[champ],
        operation: 'lt',
        join: search[champ + '_'],
        non: search[champ + '_non']
      }
    } else if (champ && champ[0] === '>') {
      return {
        champ: champ.substring(1, champ.length),
        valeur: search[champ],
        operation: 'gt',
        join: search[champ + '_'],
        non: search[champ + '_non']
      }
    } else if (champ && champ[0] === '!' && typeof search[champ] === 'string') {
      return {
        champ: champ.substring(1, champ.length),
        valeur: search[champ],
        operation: typeof search[champ] === 'string' && 'commence',
        join: search[champ + '_'],
        non: search[champ + '_non']
      }
    } else if (champ && champ[0] === '_') {
      return {
        champ: champ.substring(1, champ.length),
        valeur: [undefined, ''],
        operation: 'egal',
        operation_logic: 'AND',
        non: search[champ] === 1,
        join: search[champ + '_']
      }
    } else {
      return {
        champ: champ,
        valeur: search[champ],
        operation: typeof search[champ] === 'string' && 'contient' || Array.isArray(search[champ]) && 'egal' || 'egal',
        join: search[champ + '_'],
        non: search[champ + '_non']
      }
    }
  })
}

const optionsPays = (champ = 'libelle') => {
  const listPays = Data.list(Constants.module.COMMUN, Constants.donnee.pays)
  return listPays.filter(pays => FlagAvailable.indexOf(pays.code_alpha2) > -1).map(pays => ({
    label: pays[champ],
    value: pays[champ],
    html: pays[champ],
    startAdornment: <FlagIcon style={{ paddingLeft: 5 }} code={pays.code_alpha2} size={20}/>
  }))

}

const getInputSelection = (el) => {
  var start = 0, end = 0, normalizedValue, range,
      textInputRange, len, endRange;

  if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
    start = el.selectionStart;
    end = el.selectionEnd;
  }

  return {
    start: start,
    end: end
  };
}

const offsetToRangeCharacterMove = (el, offset) => {
  return offset - (el.value.slice(0, offset).split("\r\n").length - 1);
}

const setInputSelection = (el, startOffset, endOffset) => {
  if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
    el.selectionStart = startOffset;
    el.selectionEnd = endOffset;
  } else {
    var range = el.createTextRange();
    var startCharMove = offsetToRangeCharacterMove(el, startOffset);
    range.collapse(true);
    if (startOffset === endOffset) {
      range.move("character", startCharMove);
    } else {
      range.moveEnd("character", offsetToRangeCharacterMove(el, endOffset));
      range.moveStart("character", startCharMove);
    }
    range.select();
  }
}

const findparentClass = (node, clsName) => {
  if(node.className && node.className.indexOf(clsName) === -1 && node.parentNode){
    return findparentClass(node.parentNode, clsName);
  }else if(node.className && node.className.indexOf(clsName) !== -1){
    return true;
  }
}

const getTitleObject = (obj, column = 'libelle') => {
  if(!obj || !obj[column]) return ''
  if(typeof obj[column] === 'string') {
    return obj[column]
  } else if(Array.isArray(obj[column])){
    const langs = Data.list(Constants.module.COMMUN, Constants.donnee.langue, { traduction: 1 }).filter(langue => $lang(langue.id))
    const langSelected = ((langs || []).find(l => l.id === User.User.get().langue_id) || {}).id || (langs[0] || {}).id || 1
    const t = obj[column] && obj[column].length > 0 ? (obj[column].find(o => o.langue_id === langSelected) || obj[column][0]) : {}
    return t.text || ''
  }
}

const isLocalStorageEnable = _ => {
  try {
    if (typeof localStorage !== 'undefined') {
          localStorage.setItem('feature_test', 'yes');
          if (localStorage.getItem('feature_test') === 'yes') {
              localStorage.removeItem('feature_test');
                  return true
          } else {
              return false
          }
    } else {
      return false
    }
  } catch(e) {
    return false
  }
}

export default {
  isJsonStringObject,
  isJsonStringArray,
  isJsonString,
  isSubComponentAvailable,
  uuidv4,
  sleep,
  getRandomNumber,
  enumerateDaysBetweenDates,
  reservationColor,
  generateArray,
  searchJsonToRechercheArray,
  optionsPays,
  getInputSelection,
  setInputSelection,
  findparentClass,
  getTitleObject,
  isLocalStorageEnable
}
