import React, {useMemo} from 'react'
import {Grid, Hidden} from "@material-ui/core";
import User from "Class/User";
import Picture from "Components/Picture";
import Constants from "Constants";
import {useHistory} from "react-router";


export default React.memo(props => {
    const history = useHistory();
    const etablissementLibelle = () => {
        return User && User.Entite && User.Entite.get() && (User.User.get().id === 3 && User.TypeAccess.get(Constants.access.EURESTO) && 'Sud Mécanographie' || User.Entite.get().libelle) || 'Euresto'
    }
    const libelleEts = useMemo(etablissementLibelle, [User && User.Entite && User.Entite.get()])
    const handleClickLogo = () => {
        props.handleClick && props.handleClick()
        history.push("/");
    }

    return <Grid container direction={'row'} alignItems={"center"} alignContent={"center"} justify={"center"} style={{height: 40}}>
        <Grid onClick={handleClickLogo} container
              style={{
                  margin: 0, padding: 0, cursor: 'pointer', width: '100%', height: 40,
                  //borderBottom: '1px solid #A9A9A9',
                  //background: '#E9E9E9'
              }} spacing={0}
              justify={'flex-start'} alignItems={'center'}>
            <Grid item style={{margin: 0, padding: 0, width: 40, height: '100%'}}>
                {User && User.Entite && User.Entite.get() && User.Entite.get().media_lien &&
                <div style={{
                    postion: 'relative',
                    padding: 2.5,
                    width: 35,
                    maxWidth: 35
                }}>
                    <Picture
                        avatarStyle={{borderRadius: 5, maxWidth: 35, maxHeight: 35}}
                        disabled={true}
                        type={Constants.typeSaisie.Picture}
                        defineData={true}
                        data={{id: User.Entite.get().media_id, lien: User.Entite.get().media_lien}}
                        object={{
                            picture: {id: User.Entite.get().media_id, lien: User.Entite.get().media_lien},
                        }}/>
                </div>
                ||
                <div style={{
                    position: 'relative',
                    padding: 2.5,
                    width: 40,
                    maxWidth: 40,
                    height: '100%'
                }}>
                        <span className={`ei-logo_BE ${props.beColor || 'EurestoSecondary'}--text`} style={
                            {
                                fontSize: 25,
                                zIndex: 1,
                                fontWeight: 12,
                                position: 'absolute',
                                top: 8,
                                left: 11
                            }
                        }/>
                    {
                        <span className={`ei-logo_E ${props.eColor || 'EurestoText'}--text`} style={
                            {
                                position: 'absolute',
                                fontSize: 30,
                                top: 5,
                                fontWeight: 12,
                                zIndex: 2,
                                left: 5,
                            }
                        }/>}
                </div>
                }
            </Grid>
            <Hidden xsDown>
                <Grid item xs style={{margin: 0, padding: 0, height: 40, width: 130, position: 'relative'}}
                      className={'EurestoText--text'}>
                    <Grid container style={{height: 40}} spacing={0} direction={'column'} justify={'center'}
                          alignItems={'flex-start'}>
                    <span style={{
                        margin: 0,
                        padding: 2.5,
                        paddingTop: 5,
                        width: 130,
                        //height: 40,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'pre-wrap',
                        lineHeight: '1.4em',
                        //maxHeight: 40,
                        fontSize: '1em',
                        display: 'block'
                    }}>{libelleEts}</span>
                    </Grid>
                </Grid>
            </Hidden>
        </Grid>
    </Grid>
})