import React from 'react'

export default class Timer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      time: null
    }
  }

  componentDidMount () {
    this.intervalID = setInterval(this.refreshClock, 1000)
  }

  refreshClock = () => {
    let date = new Date()
    let month = date.getMonth()
    let monthStr = month.toString()

    if (monthStr.length <= 1) {
      monthStr = '0' + monthStr
    }

    this.setState({
      time: (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ':' + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds()
    })
  }


  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render () {
    if(this.props.onlyClock)
      return <span>{this.state.time}</span>

    return (
      <div style={{
        width: '90%',
        height: 'auto',
        margin: '10px auto',
        padding: '5px',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <span style={{fontFamily: 'Ds Digital', fontSize: '55px'}}>{this.state.time}</span>
      </div>
    )
  }
}
