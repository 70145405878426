import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { NavLink } from "react-router-dom";
import MenuData from 'Static/Data'
import Timer from 'Components/Timer'
import ScrollBar from 'Components/Scrollbar'
import { Route, Switch } from "react-router";

import $color from 'Function/Color'

import Utils from "Function/Utils";
import Bus from "Class/Bus";
import Logo from '../Components/Logo'

export default React.memo(props => {
    const [subComponent, setSubComponent] = useState(null)
    const busGUID = useMemo(() => Utils.uuidv4(), [])

    useEffect(() => {
        Bus.$on('menu_component', d => {
            setSubComponent(d)
        }, busGUID)
        return () => {
            Bus.$off('menu_component', busGUID)
            setSubComponent(null)
        }
    }, [])

    return <Drawer variant={"persistent"} anchor={"left"} style={{ width: 200 }}
        open={props.openMenu}>
        <div style={{
            width: 200,
            borderBottom: '2px solid rgba(0,0,0,0.50)',
        }} className={'EurestoPrimary'}>
            <Logo />
            <ScrollBar
                maxHeight={"calc(100vh - 74px)"}>
                <List style={{ padding: 0, margin: 0 }}>
                    {MenuData.filter(mod => mod.showMenu !== false && (!mod.autorisation || mod.autorisation())).map((mod, i) => (
                        <Grid container spacing={0} style={{ margin: 0, padding: 0, width: '100%' }}
                            key={mod.module + '-' + i} direction={"column"} justify={"flex-start"}
                            alignContent={"flex-start"} alignItems={"stretch"}>
                            <ListItem key={mod.label} style={{
                                width: '100%',
                                fontSize: 17,
                                padding: '2.5px 0px 2.5px 2.5px',
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                                position: 'relative',
                                margin: 0
                            }} className={'EurestoText'}>
                                <ListItemIcon style={{ minWidth: 'auto' }}>
                                    <i
                                        style={{ fontSize: 30, position: 'relative', zIndex: 1, paddingRight: 7 }}
                                        className={'ei-commun_cadre EurestoPrimary--text'}>
                                        <i style={{ fontSize: 20, position: 'absolute', top: 5, left: 5, zIndex: -1 }}
                                            className={mod.icon + ' EurestoSecondary--text'} />
                                    </i>
                                </ListItemIcon>
                                <ListItemText className={'EurestoPrimary--text EurestoText'}
                                    style={{
                                        margin: 0,
                                        fontSize: 17,
                                        position: 'relative',
                                        padding: '5px 0px .5px 2.5px',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5
                                    }} primary={mod.module} />
                            </ListItem>
                            {mod.children.filter(men => men.showMenu !== false && (!men.autorisation || men.autorisation())).map((men, j) => {
                                return <React.Fragment key={men.label + '-' + j}>
                                    <ListItem component={NavLink} exact to={mod.path + men.path} style={{
                                        width: '100%',
                                        fontSize: 15,
                                        padding: '.5px 0px .5px 2.5px',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5,
                                        position: 'relative',
                                        margin: 0
                                    }} button className={'MenuItemList EurestoText--text'}>
                                        <ListItemIcon style={{ minWidth: 'auto', color: 'inherit' }}>
                                            <i className={men.icon}
                                                style={{ padding: '0px 5px 0px 2.5px', color: 'inherit' }} />
                                        </ListItemIcon>
                                        <ListItemText style={{ minWidth: 'auto', color: 'inherit', padding: '5px 0px .5px 2.5px' }}
                                            primary={men.label} />
                                    </ListItem>
                                    {subComponent &&
                                        <List style={{
                                            width: '100%',
                                            fontSize: 15,
                                            padding: 0,
                                            borderLeft: '3px solid ' + $color('secondary'),
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                            position: 'relative',
                                            margin: 0
                                        }} className={'EurestoText--text'}>
                                            <Switch>
                                                <Route exact path={mod.path + men.path}>
                                                    {subComponent}
                                                </Route>
                                            </Switch>
                                        </List>}
                                </React.Fragment>
                            })}
                        </Grid>
                    ))}
                </List>
            </ScrollBar>
        </div>
        <div className="EurestoPrimary EurestoText--text"
            style={{
                padding: 5,
                borderTop: '1px solid rgba(255,255,255,0.20)',
                height: 31,
                maxHeight: 31
            }}>
            <div style={{
                postion: 'relative', display: 'inline'
            }}>
                <i className={'ei-logo_E'} style={
                    {
                        fontSize: 18,
                        zIndex: 1,
                        fontWeight: 10,
                        marginRight: -3
                    }
                } />
            </div>
            <strong className={'EurestoText--text'}>uresto</strong>
            <strong className={'EurestoSecondary--text'}>WEB</strong>
            <span className={'pull pull-right EurestoText--text'} style={{ fontSize: 11, marginTop: 7.5 }}>
                <Timer onlyClock={true} />
            </span>
        </div>
    </Drawer>
})
