import React, { useEffect, useMemo, useState } from 'react';
import 'Style/font/font/style.css'
import 'App.css';
import Menu from 'Static/Menu'
import { BrowserRouter as Router, } from "react-router-dom";
import Footer from "Static/Footer";
import Header from 'Static/Header/desktop'
import { Box, Grid, MuiThemeProvider, useMediaQuery } from "@material-ui/core";
import Route from 'Static/Route'
import { useSnackbar } from 'notistack';
import Style from 'Static/Style'
import Data from 'Class/Data'
import User from 'Class/User'
import Utils from 'Function/Utils'
import { connectWS, isWebSocketConnected, WebSocketDisconnect } from 'Websocket/index'
import ScrollBar from "Components/Scrollbar";
import { LastLocationProvider } from 'react-router-last-location';
import $storage from 'Class/Storage'
import $store from 'Class/Store'
import { useHistory } from "react-router-dom";

export default React.memo(props => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [style, setStyle] = useState(Style())
    const [isConnected, setConnected] = useState()
    const [isConnectedAs, setConnectedAs] = useState(false)
    const [connectionAvailable, setConnectionAvailable] = useState(false)
    const [downloaded, setDownloaded] = useState(false)
    const [openMenu, setOpenMenu] = useState(true);
    const [navigation, setNavigation] = useState(false);
    const matchesSm = useMediaQuery('(min-width:600px)');
    const matchesXs = useMediaQuery('(min-width:0px)');
    const history = useHistory()

    const disconnection = async () => {
        if (isWebSocketConnected()) {
            WebSocketDisconnect()
        }
        $storage.setItem('token', '')
        $store.setItem('token', '')
        Data.clearAll()
        setConnected(false)
        setConnectionAvailable(false)
        setDownloaded(false)
        setConnectedAs(false)
    }

    useEffect(() => {
        User.Disconnect.set(disconnection)
        Data.init()
        $storage.setItem('app_identifier', Utils.uuidv4())

        if (history && history.location && history.location.state) {
            const state = history.location.state

            console.log("STATE", state)

            if (Boolean(state.passwordForgot)) {
                setConnected(false)
                setConnectionAvailable(false)
                setDownloaded(false)
                return;
            }

            if (state.token) {
                $storage.setItem('token', state.token)
                $store.setItem('token', state.token)
                setConnectionAvailable(true)
            }

            if (state.navigation) {
                $storage.setItem('navigation', state.navigation !== 'off')
                if (state.navigation !== 'off') {
                    setOpenMenu(true)
                    setNavigation(true)
                } else {
                    setOpenMenu(false)
                    setNavigation(false)
                }
            } else {
                setOpenMenu(true)
                setNavigation(true)
            }
            if (state.isConnectedAs) {
                $storage.setItem('isConnectedAs', Boolean(state.isConnectedAs))
                setConnectedAs(true)
            }
        }

        if (($storage.getItem('token') === undefined || $storage.getItem('token') === null || $storage.getItem('token') === 'null' || $storage.getItem('token') === '')
            && ($store.getItem('token') === undefined || $store.getItem('token') === null || $store.getItem('token') === 'null' || $store.getItem('token') === '')) {
            $storage.setItem('token', '')
            $store.setItem('token', '')
            setConnected(false)
            setConnectionAvailable(false)
        } else if ($store.getItem('token') !== '' && $store.getItem('token') !== undefined && $store.getItem('token') !== null && $store.getItem('token') !== 'null') {
            $storage.setItem('token', $store.getItem('token'))
            setConnectionAvailable(true)
        }

        User.ColorWebSite.init(changeColor)
        User.ColorWebSite.change()


        User.NotificationSystem.set((notification) => {
            const uuid = Utils.uuidv4()
            notification && notification.message && enqueueSnackbar(notification.message, {
                autoHideDuration: 2000,
                key: uuid,
                variant: notification.variant || 'success',
                action: (key) => (
                    <i
                        onClick={() => closeSnackbar(key)}
                        style={{
                            height: '100%',
                            left: 0,
                            position: 'absolute',
                            top: 0,
                            width: '100%',
                        }} />
                )
            })
        })
    }, [])

    const waitConnection = async _ => {
        start()
            .then((r) => {
                User.ColorWebSite.change()
                connectWS()
                window.addEventListener('focus', function (event) {
                    if ((
                        $store.getItem('token') === ''
                        || $store.getItem('token') === undefined
                        || $store.getItem('token') === null
                        || $store.getItem('token') === 'null') && User.User.get().id) {
                        User.Disconnect.get()
                    } else {
                        $store.setItem('token', $storage.getItem('token'))
                        //connectWS()
                    }
                }, false)
                setDownloaded(true)
                setConnected(true)
            })
    }

    const start = async () => await Data.download()

    const changeColor = () => {
        setStyle(Style())
    }

    const handleDrawerClose = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setOpenMenu(!openMenu)
    }

    useEffect(() => {
        connectionAvailable && waitConnection()
    }, [connectionAvailable])

    const handleConnection = (token) => {
        $store.setItem('token', token)
        $storage.setItem('token', token)
        setConnectionAvailable(true)
    }

    const blockColor = useMemo(() => <div key={style} style={{ display: 'none' }}>
        <div className={'EurestoPrimary EurestoPrimary--text'} />
        <div className={'EurestoSecondary EurestoSecondary--text'} />
        <div className={'EurestoError EurestoError--text'} />
        <div className={'EurestoText EurestoText--text'} />
        <div className={'disabled'} />
    </div>, [])

    const showHeaderMenu = useMemo(() => navigation && isConnected && matchesXs && !matchesSm && 'mobile' || navigation && isConnected && matchesSm && 'desktop', [navigation, isConnected, matchesXs, matchesSm])

    const header = useMemo(() => showHeaderMenu === 'desktop' && <Grid item xs={12}>
        <Header />
    </Grid>, [showHeaderMenu, isConnected, downloaded])

    const menu = useMemo(() => showHeaderMenu === 'desktop' && <>
        <Menu style={{ height: '100%' }} openMenu={openMenu} />
        <i className={`ei-commun_burger_menu cursor-pointer ${openMenu && 'EurestoText--text' || 'EurestoPrimary--text'}`}
            style={{
                left: openMenu && 170 || 10,
                fontSize: 25,
                top: 7.5,
                position: 'fixed',
                zIndex: 2000,
                transition: 'all .25s ease-out'
            }} onClick={handleDrawerClose} />
    </> || showHeaderMenu === 'mobile' && <Footer />, [showHeaderMenu, openMenu, downloaded])

    const routes = useMemo(() => <Route downloaded={downloaded} connectionAvailable={connectionAvailable}
        connected={isConnected}
        handleConnection={handleConnection} />, [downloaded, connectionAvailable, isConnected, history])

    const boxWidth = useMemo(() => openMenu && showHeaderMenu === 'desktop' && 'calc(100vw - 200px)' || '100vw', [openMenu, showHeaderMenu])
    const boxMarginTop = useMemo(() => openMenu && showHeaderMenu === 'desktop' && '40px' || 0, [showHeaderMenu])
    const boxHeight = useMemo(() => (showHeaderMenu === 'desktop' || showHeaderMenu === 'mobile') && 'calc(100vh - 40px)' || '100vh', [showHeaderMenu])

    return (
        <MuiThemeProvider theme={style}>
            {blockColor}
            <Grid container direction={'row'} justify={'center'}>
                <Router>
                    <LastLocationProvider>
                        {header}
                        {menu}
                        <Box
                            style={{ position: 'relative', width: boxWidth, height: boxHeight, marginTop: boxMarginTop }}>
                            <ScrollBar
                                mainScrollBar
                                maxHeight={"100%"}>
                                {routes}
                            </ScrollBar>
                        </Box>
                    </LastLocationProvider>
                </Router>
            </Grid>
            {isConnectedAs && <div style={{
                height: 31,
                position: 'absolute',
                backgroundColor: 'lightgrey',
                bottom: showHeaderMenu === 'mobile' && 40 || 0,
                width: '100%',
                borderTop: '2px solid rgba(0,0,0,0.50)',
                //borderTop: '1px solid rgba(255,255,255,0.20)'
            }}>
                <Grid container style={{ margin: 0, padding: 0, height: '100%' }} spacing={0} justify={'center'}
                    alignItems={'center'}>
                    Vous êtes connecté en tant que ~&nbsp;
                    <strong>{User && User.Entite && User.Entite.get() && User.Entite.get().libelle}</strong>
                    &nbsp;~ (<a href="javascript:window.close();">Quitter</a>)
                </Grid>
            </div>}
        </MuiThemeProvider>
    );
})
