import React from 'react'
import Constants from 'Constants'
import Data from 'Class/Data'
import User from 'Class/User'
import $option from 'Function/Option'


//HOME
const HomeDashboard = React.lazy(_ => import('Module/Home/Routes/Dashboard'))

//CRM
const CRMAjout = React.lazy(_ => import('Module/CRM/Routes/Ajout'))
const CRMRecherche = React.lazy(_ => import('Module/CRM/Routes/Recherche'))
const CRMModification = React.lazy(_ => import('Module/CRM/Routes/Modification'))
const CRMOutils = React.lazy(_ => import('Module/CRM/Routes/Outils'))

//SDD
const SDDRecherche = React.lazy(_ => import('Module/SDD/Routes/Recherche'))
const SDDCas = React.lazy(_ => import('Module/SDD/Routes/Cas'))
const SDDMessage = React.lazy(_ => import('Module/SDD/Routes/Message'))

//ALLIANCE RÉSEAUX
const AllianceReseauxRecherche = React.lazy(_ => import('Module/AllianceReseaux/Routes/Recherche'))

//DOCUMENT
const DocumentRecherche = React.lazy(_ => import('Module/Document/Routes/Recherche'))
const DocumentFiche = React.lazy(_ => import('Module/Document/Routes/Fiche'))

//GESTION
const GestionAjout = React.lazy(_ => import('Module/Gestion/Routes/Ajout'))
const GestionModification = React.lazy(_ => import('Module/Gestion/Routes/Modification'))
const GestionRecherche = React.lazy(_ => import('Module/Gestion/Routes/Recherche'))

//FIDELITÉ
const FideliteRecherche = React.lazy(_ => import('Module/Fidelite/Routes/Recherche'))

//RESTAURANT
const RestaurantGestion = React.lazy(_ => import('Module/Restaurant/Routes/Gestion'))

//CONFIGURATION
const Configuration = React.lazy(_ => import('Module/Configuration/Routes/Configuration'))

///NF525
const NFCategorie = React.lazy(_ => import('Module/NF525/Routes/Categorie'))
const NFExigence = React.lazy(_ => import('Module/NF525/Routes/Exigence'))
const NFVersionning = React.lazy(_ => import('Module/NF525/Routes/Versioning'))
const NFPlanTest = React.lazy(_ => import('Module/NF525/Routes/PlanTest'))

export default [
    {
        module: 'Home',
        path: '/',
        showMenu: false,
        children: [{
            module: 'Dashboard',
            path: '',
            component: HomeDashboard
        }]
    }, {
        module: 'CRM',
        icon: ' ei-module_crm',
        path: '/crm',
        children: [
            {
                label: 'Ajout',
                path: '/ajout',
                icon: ' ei-commun_ajouter',
                component: CRMAjout
            },
            {
                label: 'Recherche',
                path: '',
                icon: ' ei-commun_rechercher',
                component: CRMRecherche,
                props: {
                    defaultSearch: {
                        "?actif": 1
                    }
                }
            },
            {
                label: 'Filtre',
                path: '/filtre',
                icon: ' ei-commun_filtrer',
                component: CRMOutils,
            },
            {
                label: 'Outils',
                path: '/outis',
                icon: ' ei-module_crm_4',
                component: CRMOutils,
            },
            {
                showMenu: false,
                path: '/:id',
                component: CRMModification
            },
        ]
    },
    {
        module: 'Produit',
        icon: ' ei-module_gestion',
        path: '/gestion',
        autorisation: _ => {
            return User.TypeAccess.get(Constants.access.ETABLISSEMENT) && ($option(Constants.options[Constants.methods.GESTION].id) || $option(Constants.options[Constants.methods.FACTURATION].id))
        },
        children: [
            {
                label: 'Ajout',
                icon: ' ei-commun_ajouter',
                path: '/ajout',
                component: GestionAjout,
            },
            {
                path: '/:id',
                showMenu: false,
                component: GestionModification,
            },
            {
                label: 'Mercuriale',
                path: '/mercuriale',
                icon: ' ei-gestion_famille',
                component: GestionRecherche,
                props: {
                    module: 'mercuriale'
                }
            },
            {
                label: 'FT Base',
                path: '/ft-base',
                icon: ' ei-gestion_produit',
                component: GestionRecherche,
                props: {
                    module: 'ft-base'
                }
            },
            {
                label: 'Produit & FT',
                path: '/vente-ft',
                icon: ' ei-gestion_fiche_technique',
                component: GestionRecherche,
                props: {
                    module: 'vente-ft'
                }
            },
            {
                label: 'Vin',
                path: '/vin',
                icon: ' ei-gestion_vin',
                component: GestionRecherche,
                props: {
                    module: 'vin'
                }
            },
            {
                label: 'Dbm',
                path: '/dbm',
                icon: ' ei-commun_rechercher',
                autorisation: _ => {
                    return $option(Constants.options[Constants.methods.GESTION].DBM.id)
                },
                component: GestionRecherche,
                props: {
                    module: 'dbm'
                }
            },
        ]
    },
    {
        module: 'NF525',
        icon: ' ei-module_reservation',
        path: '/nf525',
        autorisation: _ => User.User.get().id === 2 || User.User.get().id === 8,
        children: [
            {
                label: 'Catégorie',
                path: '/categorie',
                icon: ' ei-commun_rechercher',
                component: NFCategorie,
            },
            {
                label: 'Exigence',
                path: '/exigence',
                icon: ' ei-commun_rechercher',
                component: NFExigence,
            },
            {
                label: 'Version',
                path: '/versioning',
                icon: ' ei-commun_rechercher',
                component: NFVersionning,
            },
            {
                label: 'Plans Test',
                path: '/plan_test',
                icon: ' ei-commun_rechercher',
                component: NFPlanTest,
            },
        ]
    },
    {
        module: 'Restaurant',
        icon: ' ei-module_reservation',
        path: '/restaurant',
        autorisation: _ => {
            return User.TypeAccess.get(Constants.access.ETABLISSEMENT)
                && $option(Constants.options[Constants.methods.RESTAURANT].id)
        },
        children: [
            {
                label: 'Réservation',
                path: '',
                icon: ' ei-commun_rechercher',
                component: RestaurantGestion,
            },
        ]
    },
    {
        module: 'Fidélité',
        icon: ' ei-module_fidelite',
        path: '/fidelite',
        autorisation: _ => User.TypeAccess.get(Constants.access.ETABLISSEMENT) && $option(Constants.options[Constants.methods.FIDELITE].id),
        children: [
            {
                label: 'Recherche',
                path: '',
                icon: ' ei-commun_rechercher',
                component: FideliteRecherche,
            },
        ]
    },
    {
        module: 'Suivi Dossiers',
        icon: ' ei-module_sdd',
        path: '/sdd',
        children: [
            {
                label: 'Recherche',
                path: '',
                icon: ' ei-commun_rechercher',
                component: SDDRecherche,
            },
            {
                label: 'Email/Sms',
                path: '/message',
                icon: ' ei-commun_commentaire',
                component: SDDMessage
            },
            {
                label: 'Bug',
                path: '/bug',
                icon: ' ei-commun_redmine',
                component: SDDCas,
                props: {
                    searchName: 'bug_'
                }
            },
            {
                label: 'Développement',
                path: '/developpement',
                icon: ' ei-sdd_dossier',
                component: SDDCas,
                props: {
                    searchName: 'developpemnt_'
                }
            },
        ]
    },
    {
        module: 'Documents',
        icon: ' ei-module_sdd',
        path: '/document',
        autorisation: () => User.User.get().id === 2 && $option(Constants.options[Constants.methods.GESTION].id),
        children: [
            {
                label: 'Ajout',
                path: '/ajout',
                icon: ' ei-commun_ajouter',
                component: DocumentFiche,
            },
            {
                showMenu: false,
                path: '/:id',
                icon: ' ei-commun_ajouter',
                component: DocumentFiche,
            },
            {
                label: 'Recherche',
                path: '',
                icon: ' ei-commun_rechercher',
                component: DocumentRecherche,
            },
        ]
    },
    {
        module: 'Alliance Réseaux',
        icon: ' ei-module_sdd',
        path: '/alliance-reseaux',
        autorisation: _ => $option(Constants.options[Constants.methods.INTERFACE].ALLIANCE_RESEAUX.id),
        children: [
            {
                label: 'Suivi des ventes',
                path: '/ventes',
                icon: ' ei-commun_rechercher',
                component: AllianceReseauxRecherche,
            },
        ]
    },
    {
        module: 'Configurations',
        path: '/configuration',
        icon: ' ei-module_configuration',
        autorisation: user => Data.list(Constants.module.CONFIGURATION, Constants.donnee.option).length > 0,
        children: [
            {
                label: 'CRM',
                path: '/crm',
                icon: ' ei-module_crm',
                component: Configuration,
                props: {
                    module: 'crm'
                }
            },
            {
                label: 'Gestion',
                path: '/gestion',
                icon: ' ei-module_gestion',
                autorisation: _ => ($option(Constants.options[Constants.methods.GESTION].id) || $option(Constants.options[Constants.methods.FACTURATION].id)),
                component: Configuration,
                props: {
                    module: 'gestion'
                }
            },
            {
                label: 'Facturation',
                path: '/facturation',
                autorisation: _ => $option(Constants.options[Constants.methods.FACTURATION].id),
                icon: ' ei-module_gestion',
                component: Configuration,
                props: {
                    module: 'facturation'
                }
            },
            {
                label: 'Restaurant',
                path: '/restaurant',
                autorisation: _ => User.TypeAccess.get(Constants.access.ETABLISSEMENT) && $option(Constants.options[Constants.methods.RESTAURANT].id),
                icon: ' ei-module_reservation',
                component: Configuration,
                props: {
                    module: 'restaurant'
                }
            },
            {
                label: 'Hotel',
                path: '/hotel',
                autorisation: _ => User.TypeAccess.get(Constants.access.ETABLISSEMENT) && $option(Constants.options[Constants.methods.HOTEL].id),
                icon: ' ei-module_reservation',
                component: Configuration,
                props: {
                    module: 'hotel'
                }
            },
            {
                label: 'Template',
                path: '/template',
                icon: ' ei-module_documentation',
                component: Configuration,
                props: {
                    module: 'template'
                }
            },
            {
                label: 'Suivi de dossier',
                path: '/sdd',
                icon: ' ei-module_sdd',
                component: Configuration,
                props: {
                    module: 'sdd'
                }
            },
            {
                label: 'Etablissement',
                path: '/etablissement',
                icon: ' ei-module_configuration',
                component: Configuration,
                props: {
                    module: 'etablissement'
                }
            },
            {
                label: 'Alliance Réseaux',
                path: '/alliance_reseaux',
                autorisation: _ => $option(Constants.options[Constants.methods.INTERFACE].ALLIANCE_RESEAUX.id),
                icon: ' ei-module_configuration',
                component: Configuration,
                props: {
                    module: 'alliance_reseaux'
                }
            },
            {
                label: 'Interface',
                path: '/interface',
                autorisation: _ => User.TypeAccess.get(Constants.access.ETABLISSEMENT) && $option(3000),
                icon: ' ei-module_interface',
                component: Configuration,
                props: {
                    module: 'interface'
                }
            },
        ]
    },
    // {
    //     module: 'Documentation',
    //     icon: ' ei-module_interface',
    //     path: '/documentation',
    //     autorisation: _ => (User.User.get().id === 2)
    //         && User.TypeAccess.get(Constants.access.EURESTO),
    //     children: [
    //         {
    //             label: 'API',
    //             icon: 'ei-module_documentation',
    //             path: '',
    //             component: Configuration,
    //             props: {
    //                 module: 'crm'
    //             }
    //         },
    //     ]
    // },
]


