import $utils from 'Function/Utils'
const storage = {}
const _Storage = {
  setItem: (key, item) => {
    $utils.isLocalStorageEnable() ? localStorage.setItem(key, item) : storage[key] = item;

  },
  getItem: (key) =>  $utils.isLocalStorageEnable() ? localStorage.getItem(key) : storage[key]
}
Object.freeze(_Storage)
export default _Storage
