import React, { useEffect, useState } from 'react'
import 'dropzone/dist/min/dropzone.min.css'
import 'react-dropzone-component/styles/filepicker.css'
import DropzoneComponent from 'react-dropzone-component'

import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import Grid from '@material-ui/core/Grid/Grid'
import avatarProduit from 'Style/font/avatarProduit.png'
import avatarEntite from 'Style/font/avatarEntite.png'
import avatarPersonne from 'Style/font/avatarPersonne.png'

export default props => {

  const [visuel, setVisuel] = useState(null)

  useEffect(() => {
    setVisuel(props.visuel || <img width={'auto'}
      height={'auto'}
      style={props.avatarStyle}
      src={props.src ||
        (props.type === 'produit' && avatarProduit || props.type === 'entite' && avatarEntite || avatarPersonne)} />)
  }, [props.visuel])

  let template = props.template || ''
  let acceptedFiles
  let iconFileTypes
  let showFiletypeIcon
  let loadVisu
  let clickable = true
  switch (template) {
    case 'logo':
    case 'photo':
      acceptedFiles = 'image/*'
      showFiletypeIcon = false
      loadVisu = <img width={'auto'}
        height={'auto'}
        style={props.avatarStyle}
        src={props.src ||
          (props.type === 'produit' && avatarProduit || props.type === 'entite' && avatarEntite || avatarPersonne)} />
      break

    default:
      acceptedFiles = 'image/*,video/*,application/*,.msg,.eml,.zip,.rar,.wde,.txt'
      showFiletypeIcon = false
      clickable = false
      break
  }
  const djsConfig = {
    addRemoveLinks: false,
    acceptedFiles: acceptedFiles,
    maxFilesize: 600,
    clickable: clickable,
    previewElement: false,
    autoProcessQueue: false
  }

  const config = {
    iconFiletypes: iconFileTypes,
    showFiletypeIcon: showFiletypeIcon,
    postUrl: 'no-url',
    clickable: clickable
  }

  if (props.children) {
    return <div onPaste={this.onPaste} style={Object.assign({ position: 'relative' }, props.style || {})}>{props.disabled !== true ? (
      <DropzoneComponent config={config} djsConfig={djsConfig}>
        <div className="dz-message" style={{ margin: 0 }}>{props.children}</div>
        {
          this.state.uploading &&
          <Grid container spacing={0} style={{ margin: 0, padding: 0, height: '100%', width: '100%', position: 'absolute', left: 0, top: 0 }}
            direction="column" justify={'center'} alignContent={'center'} alignItems={'center'}><CircularProgress
              size={100} thickness={4} style={{ zIndex: 100 }} className={'EurestoPrimary--text'} />
          </Grid>
        }
      </DropzoneComponent>) : <div className="dz-message" style={{ margin: 0 }}>{props.children}</div>}</div>
  } else {
    return (<div style={props.style || {}}>{props.disabled !== true ? (
      <DropzoneComponent config={config} djsConfig={djsConfig}>
        <div className="dz-message" style={{ margin: 0 }}>{visuel}</div>
      </DropzoneComponent>) : <div className="dz-message">{visuel}</div>}</div>)
  }
}