import React from 'react';
import Avatar from '@material-ui/core/Avatar'

import avatarPersonne from 'Style/font/avatarPersonne.png'
import avatarProduit from 'Style/font/avatarProduit.png'
import avatarEntite from 'Style/font/avatarEntite.png'

export default class extends React.Component {
  constructor (props){
    super(props)
  }
  render(){
    let image;
    if (this.props.src !== undefined && this.props.src !== '' && this.props.src !== null  && this.props.src !== 'null'){
      image = this.props.src
    }else{
      image = this.props.defaultImg || this.props.type === 'produit' && avatarProduit || this.props.type === 'entite' && avatarEntite || avatarPersonne
    }

    const style = Object.assign({width: '64px', height: '64px', padding: 0}, this.props.style || {})

    return (
      <Avatar
        src={image}
        style={style}/>
    )
  }
}
