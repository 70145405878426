import io from 'socket.io-client'

import project from 'Config/project'
import User from 'Class/User'
import Constants from 'Constants'
import Data from 'Class/Data'
import Request from 'Api/Request'
import $storage from 'Class/Storage'

function listenerSocket(key, module, donnee) {
  return (data) => {
    console.log('IUAGZIUDGUIAGUDAUGDUAIUZDGAZGD', data)
    let notGood = false
    console.log(key, data, module, donnee)
    if (data.action !== Constants.broadcastAction.delete) {
      Request(data.path, data.params, { showAlertError: false })
        .then(result => {
          switch (data.action) {
            case Constants.broadcastAction.add:
              result.results.map((result) => {
                const d = Data.get(module, donnee, { [Constants.dataObject[key].modifSearch]: result[Constants.dataObject[key].modifSearch] })
                if (!d || !d[Constants.dataObject[key].modifSearch]) {
                  Data.add(module, donnee, result)
                } else {
                  notGood = true
                }
              })
              break
            case Constants.broadcastAction.update:
              result.results.map((result) => {
                Data.update(module, donnee, { [Constants.dataObject[key].modifSearch]: result[Constants.dataObject[key].modifSearch] }, result)
              })
              break
            case Constants.broadcastAction.replace:
              Data.set(module, donnee, result.results)
              break
            default:
              notGood = true
              break
          }
          data.results = result.results
          if (!notGood) {
            Data.restate(module, donnee, data)
          }
        })
    } else {
      Data.rm(module, donnee, { [Constants.dataObject[key].modifSearch]: data.params[Constants.dataObject[key].modifSearch] })
      Data.restate(module, donnee, data)
    }
    /*switch (data.action) {
      case Constants.broadcastAction.add:
        data.results.map((result) => {
          Data.add(module, donnee, result)
        })
        break
      case Constants.broadcastAction.update:
        data.results.map((result) => {
          Data.update(module, donnee, { [Constants.dataObject[key].modifSearch]: result[Constants.dataObject[key].modifSearch] }, result)
        })
        break
      case Constants.broadcastAction.delete:
        Data.rm(module, donnee, { [Constants.dataObject[key].modifSearch]: data[Constants.dataObject[key].modifSearch] })
        break
      case Constants.broadcastAction.replace:
        Data.set(module, donnee, data.results)
        break
      default:
        notGood = true
        break
    }*/
  }
}

const WebSocketDisconnect = _ => {
  socket.disconnect()
  socket.destroy()
}

const isWebSocketConnected = () => socket.connected

const WebSocketContact = (disconnect, id, reconnection = false) => {
  if (socket !== undefined) {
    const contactsConnection = $storage.getItem('websocket_contacts') && JSON.parse($storage.getItem('websocket_contacts')) || []
    if (reconnection) {
      contactsConnection.map(contact_id => {
        WebSocketContact(false, contact_id)
      })
      return
    } else {
      if (disconnect) {
        const i = contactsConnection.indexOf(id)
        if (i !== -1) {
          contactsConnection.splice(i, 1)
        }
      } else {
        const i = contactsConnection.indexOf(id)
        if (i === -1) {
          contactsConnection.push(id)
        }
      }
      $storage.setItem('websocket_contacts', JSON.stringify(contactsConnection))
    }

    socket.emit('subscribeContact', {
      token: $storage.getItem('token'),
      disconnect: disconnect,
      id: id
    })
    if (!disconnect) {
      socket.off('get').on('get', (data) => {
        User.ContactIdRegisterCallBack.getCbGet().map(cb => {
          cb(data)
        })
      })

      socket.off('dossier').on('dossier', (data) => {
        User.ContactIdRegisterCallBack.getCbDossier().map(cb => {
          cb(data)
        })
      })

      socket.off('action').on('action', (data) => {
        User.ContactIdRegisterCallBack.getCbAction().map(cb => {
          cb(data)
        })
      })

      socket.off('fichier').on('fichier', (data) => {
        User.ContactIdRegisterCallBack.getCbFichier().map(cb => {
          cb(data)
        })
      })
    }
  }
}//true

const suscribeDataWS = _ => {
  socket.emit('subscribe', {
    token: $storage.getItem('token'),
  })
  Object.keys(Constants.dataObject).map((key) => {
    if (Constants.dataObject[key].websocket) {
      const [module, donnee] = key.split('-')
      socket.on(key, listenerSocket(key, module, donnee))
    }
  })
}

const connectWS = _ => {
  if (!socket.connected || socket.disconnected) {
    socket.connect()
  }
}

const socket = io(project.api, {
  transports: project.socketTransport,
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity
})
  .on('disconnect', reason => {
    if (reason !== 'io client disconnect') {
      socket.connect()
    } else {
      console.debug(reason)
    }
  })
  .on('error', (error) => {
    console.error('error', error)
  })
  .on('connect', (error) => {
    if ((
      $storage.getItem('token') === ''
      || $storage.getItem('token') === undefined
      || $storage.getItem('token') === null
      || $storage.getItem('token') === 'null')) {
      WebSocketDisconnect()
    } else {
      suscribeDataWS()
      WebSocketContact(false, undefined, true)
    }
  })
  .on('reconnect', (attemptNumber) => {
    console.debug('reconnect', attemptNumber)
  })
  .on('destroy', (attemptNumber) => {
    console.debug('destroy', attemptNumber)
  })

export {
  connectWS,
  WebSocketContact,
  WebSocketDisconnect,
  isWebSocketConnected
}
