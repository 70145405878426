import Constants from 'Constants'
import Request from 'Api/Request'
import $storage from 'Class/Storage'

const User = (() => {
    let info = ''


    let isConnectedAs = _ => {
        return (info.type === Constants.access.EURESTO && Entite.get().type !== Constants.access.EURESTO) ||
            (info.type === Constants.access.USER_GROUPE && Entite.get().type !== Constants.access.GROUPE) ||
            (info.type === Constants.access.USER_ETS && Entite.get().type !== Constants.access.ETABLISSEMENT)
    }

    const get = () => {
        return info
    }

    const set = (t) => {
        info = t
    }

    return {
        get: get,
        set: set,
        isConnectedAs: isConnectedAs
    }
})()

const Entite = (() => {
    let info = ''

    const get = () => {
        return info
    }

    const set = (t) => {
        info = t
    }

    return {
        get: get,
        set: set
    }
})()

const Droits = (() => {
    let droits = []

    const isAllow = (t) => {
        return droits.indexOf(t) > -1
    }

    const list = () => {
        return droits
    }

    const set = (t) => {
        droits = t
    }

    const add = (t) => {
        droits.push(t)
    }

    const rm = (t) => {
        const pos = droits.indexOf(t)
        if (pos > -1) {
            droits.splice(pos, 1)
        }
    }

    return {
        isAllow: isAllow,
        list: list,
        set: set,
        add: add,
        rm: rm
    }
})()

const isAddUpdateDeleteAvaibleForMe = () => {
    return true
}

const Connect = (() => {
    const go = async (key) => {
        let token = ''
        const sessionTokenResult = await Request('configuration', {
            requete: 'user',
            action: 'token',
            key: key
        })
        if (sessionTokenResult && sessionTokenResult.code === 1) {
            token = sessionTokenResult.token
        }
        return token
    }

    return {
        go: go,
    }
})()

const Disconnect = (() => {
    let cb

    const set = (t) => {
        cb = t
    }

    const get = () => {
        Entite.set({})
        User.set({})
        Request('token', {
            disconnect: true
        }).then(cb)
    }

    return {
        get: get,
        set: set,
    }
})()


const ColorWebSite = (() => {
    let cb

    const init = (t) => {
        cb = t
    }

    const change = () => {
        let themeUser = Constants.themeUI[User.get() && User.get().theme_id]
        if (themeUser === undefined) {
            themeUser = 'default'
        }
        const elem = document.querySelector('.wrapper')
        elem && elem.setAttribute('class', 'wrapper')
        elem && elem.setAttribute('class', 'wrapper ' + themeUser)
        cb()
    }

    return {
        init: init,
        change: change,
    }
})()

const TypeAccess = (() => {

    const get = (access) => {
        return (access === Entite.get().type)
    }

    return {
        get: get,
    }
})()

const UserAccess = (access) => access === User.get().type

const VinistoriaCRM = (() => {
    const get = () => {
        return Entite.get().vinistoria_crm === 1 || false
    }

    return {
        get: get,
    }
})()

const ContactIdRegisterCallBack = (() => {
    let cbGet = []
    let cbDossier = []
    let cbAction = []
    let cbFichier = []

    const set = (cb) => {
        if (cb.get !== undefined) {
            cbGet.push(cb.get)
            cb.get = cbGet.length - 1
        }
        if (cb.dossier !== undefined) {
            cbDossier.push(cb.dossier)
            cb.dossier = cbDossier.length - 1
        }
        if (cb.action !== undefined) {
            cbAction.push(cb.action)
            cb.action = cbAction.length - 1
        }
        if (cb.fichier !== undefined) {
            cbFichier.push(cb.fichier)
            cb.fichier = cbFichier.length - 1
        }

        return cb
    }

    const reset = cb => {
        if (cb.get !== undefined) {
            cbGet.splice(cb.get, 1)
        }
        if (cb.dossier !== undefined) {
            cbDossier.splice(cb.dossier, 1)
        }
        if (cb.action !== undefined) {
            cbAction.splice(cb.action, 1)
        }
        if (cb.fichier !== undefined) {
            cbFichier.splice(cb.i, 1)
        }
    }

    const getCbGet = () => {
        return cbGet
    }

    const getCbDossier = () => {
        return cbDossier
    }

    const getCbAction = () => {
        return cbAction
    }

    const getCbFichier = () => {
        return cbFichier
    }

    const resetAll = () => {
        cbGet = []
        cbDossier = []
        cbAction = []
        cbFichier = []
    }

    const resetAllFichier = () => {
        cbFichier = []
    }

    return {
        set: set,
        getCbGet: getCbGet,
        getCbDossier: getCbDossier,
        getCbAction: getCbAction,
        getCbFichier: getCbFichier,
        resetAll: resetAll,
        resetAllFichier: resetAllFichier,
        reset: reset
    }
})()

const CalculHeight = (() => {
    const getHeightWanted = (height = 0, other = '') => {
        const navigationOff = $storage.getItem('navigation') === false
        const connectedAs = $storage.getItem('isConnectedAs') === true
        const removeHeight = $storage.getItem('removeHeight') || '0px'
        return 'calc((100vh ' + (!navigationOff && '- 40px ' || '') + (connectedAs ? ' - 31px ' : '') + ' - ' + height + 'px) ' + other + ')'
    }
    return {
        getHeightWanted: getHeightWanted,
    }
})()

const NotificationSystem = (() => {
    let cb

    const set = (c) => {
        cb = c
    }

    const callback = (ns) => {
        if (cb !== undefined) {
            cb(ns)
        }
    }

    return {
        set: set,
        callback: callback
    }
})()

const MainScrollbarRef = (() => {
    let refsc

    let set = (c) => {
        refsc = c
    }

    const get = () => refsc

    return {
        set,
        get
    }
})()

export default {
    Droits,
    User,
    Entite,
    Disconnect,
    Connect,
    MainScrollbarRef,
    isAddUpdateDeleteAvaibleForMe,
    TypeAccess,
    UserAccess,
    ContactIdRegisterCallBack,
    CalculHeight,
    NotificationSystem,
    VinistoriaCRM,
    ColorWebSite
}
