import Constants from 'Constants'
import Utils from 'Function/Utils'
import _ from 'lodash'
import Request from 'Api/Request'
import User from 'Class/User'
import Bus from './Bus'

let data = {}
const cbRestate = {}

const Data = {
    init: d => {
        if (d) {
            data = d
        } else {
            Object.keys(Constants.module).map(key => {
                data[Constants.module[key]] = {}
                cbRestate[Constants.module[key]] = {}
            })
        }
    },

    download: async () => {
        return new Promise(async resolve => {
            const result = await Request('commun', {
                requete: 'data',
                action: 'list'
            }, {
                showAlertError: false,
                onDownloadProgress: progress => {
                    console.log("PROGRESS", progress)
                    let percentCompleted = Math.floor((progress.loaded * 100) / progress.total)
                    let elem = document.querySelector('#MainBlockProgress2')
                    if (elem) {
                        elem.style.width = ((148 * percentCompleted) / 100) + 'px'
                    }
                },
            })
            await Utils.sleep(800);

            if (result !== undefined) {
                User.User.set(result.user)
                User.Entite.set(result.entite)
                Data.init(result.results)
            }

            resolve()
        })
    },

    isEmpty: (module) => {
        return Object.keys(data[module]).length <= 0
    },

    get: (module, object, t) => {
        if (data[module] === undefined || data[module][object] === undefined) {
            return undefined
        }
        return (data[module][object] || []).find(obj => {
            let val = Object.keys(t).map((key) => {
                return ((!isNaN(t[key]) && !isNaN(obj[key]) ? obj[key].toString() : obj[key]) === (!isNaN(obj[key]) && !isNaN(t[key]) ? t[key].toString() : t[key]))
            })
            return val.indexOf(false) === -1
        }) || {}
    },

    list: (module, object, t = {}, onlyActif = true) => {
        if (data[module] === undefined || data[module][object] === undefined) {
            return []
        }
        return data[module][object].filter(o => !onlyActif || ((o.actif === undefined || o.actif !== 0) && (o.archive_id === undefined || o.archive_id === null))).filter(obj => {
            let val = Object.keys(t).map((key) => {
                if (t[key] !== null && t[key] !== undefined && t[key].contains !== undefined) {
                    let ObjArr = obj[key] || []
                    if (Utils.isJsonStringArray(obj[key]) !== undefined) {
                        ObjArr = Utils.isJsonStringArray(obj[key])
                    }
                    if (t[key].contains instanceof Array) {
                        return ObjArr.findIndex(o => t[key].contains.indexOf(o) !== -1) !== -1
                    } else {
                        return ObjArr.indexOf(t[key].contains) !== -1
                    }
                } else if (t[key] !== null && t[key] !== undefined && t[key].isEmpty !== undefined) {
                    let ObjArr = obj[key] || []
                    if (Utils.isJsonStringArray(obj[key]) !== undefined) {
                        ObjArr = Utils.isJsonStringArray(obj[key])
                    }
                    if (ObjArr instanceof Array) {
                        return t[key].isEmpty ? ObjArr.length === 0 : ObjArr.length > 0
                    } else if (typeof ObjArr === 'object') {
                        return t[key].isEmpty ? Object.keys(ObjArr).length === 0 : Object.keys(ObjArr).length > 0
                    }
                    return false
                } else if (t[key] !== null && t[key] !== undefined && t[key].not !== undefined) {
                    return obj[key] !== t[key].value
                } else if (t[key] !== null && t[key] !== undefined && t[key].in !== undefined) {
                    return t[key].in.indexOf(obj[key]) !== -1
                } else if (t[key] !== null && t[key] !== undefined && t[key].or !== undefined) {
                    let boolSave = false
                    let r = t[key].or.map(k => {
                        const ds = Data.list(module, object, { [key]: k }) || []
                        return boolSave || (ds.findIndex(o => o === obj) !== -1)
                    })
                    return r.indexOf(true) !== -1
                } else {
                    return obj[key] === t[key]
                }
            })
            return val.indexOf(false) === -1
        })
    },

    clearAll: (module) => {
        if (module !== undefined) {
            data[module] = {}
        } else {
            Object.keys(data).map(key => {
                delete data[key]
                delete cbRestate[key]
            })
            Object.keys(Constants.module).map(key => {
                data[key] = {}
                cbRestate[key] = {}
            })
        }
    },

    order: (module, object, orderBy = undefined) => {
        orderBy = orderBy || Constants.dataObject[module + '-' + object] && Constants.dataObject[module + '-' + object].orderBy
        if (orderBy) {
            const columnOrder = orderBy.map(o => (object => typeof object[o.split(' ')[0]] === 'string' && (object[o.split(' ')[0]]).insensitiveCaseAccents() || object[o.split(' ')[0]]))
            const sortOrder = orderBy.map(o => o.split(' ')[1] || ['asc'])
            data[module][object] = _.orderBy(data[module][object], columnOrder, sortOrder)
        }
    },

    set: (module, object, t) => {
        if (data[module] === undefined) data[module] = {}
        if (data[module][object] === undefined) data[module][object] = []

        data[module][object] = t

        Data.order(module, object)
    },

    add: (module, object, t) => {
        if (data[module] !== undefined && data[module][object] !== undefined && data[module][object].indexOf(t) === -1) {
            data[module][object].push(t)

            Data.order(module, object)
        }
    },

    update: (module, object, t, o) => {
        if (data[module] !== undefined) {
            let pos = (data[module][object] || []).findIndex(obj => {
                let val = Object.keys(t).map((key) => {
                    return ((!isNaN(t[key]) && !isNaN(obj[key]) ? obj[key].toString() : obj[key]) === (!isNaN(obj[key]) && !isNaN(t[key]) ? t[key].toString() : t[key]))
                })
                return val.indexOf(false) === -1
            })
            if (pos === -1) {
                Data.add(module, object, o)
            } else {
                Object.keys(data[module][object][pos]).map((key) => {
                    data[module][object][pos][key] = o[key]
                })

                Data.order(module, object)
            }
        }
    },

    rm: (module, object, t) => {
        if (data[module] !== undefined) {
            let pos = (data[module][object] || []).findIndex(obj => {
                let val = Object.keys(t).map((key) => {
                    return ((!isNaN(t[key]) && !isNaN(obj[key]) ? obj[key].toString() : obj[key]) === (!isNaN(obj[key]) && !isNaN(t[key]) ? t[key].toString() : t[key]))
                })
                return val.indexOf(false) === -1
            })
            if (pos > -1) {
                data[module][object].splice(pos, 1)
            }

            Data.order(module, object)
        }
    },

    concat: (module, object, t) => {
        if (data[module] === undefined) data[module] = {}
        if (data[module][object] === undefined) data[module][object] = []
        data[module][object] = data[module][object].concat(t)
        Data.order(module, object)
    },

    addCbRestate: (module, object, uuid, t) => {
        Bus.$on(module + '\/' + object, t, uuid)
        if (cbRestate[module] === undefined) cbRestate[module] = {}
        if (cbRestate[module][object] === undefined) cbRestate[module][object] = []
        cbRestate[module][object].push(t)
    },

    resetCbRestate: (module, object, uuid) => {
        Bus.$off(module + '\/' + object, uuid)
    },

    restate: (module, object, data) => {
        Bus.$emit(module + '\/' + object, data)
    },

    print: () => {
        console.debug('DATAS:', data)
    }
}
Object.freeze(Data)
export default Data
