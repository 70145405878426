const events = {}
const _EventBus = {
  $on: (id, callback, key) => {
    events[id] = {
      ...(events[id] || {}),
      [key]: callback
    }
  },
  $emit: (id, ...variaveis) => {
    Object.values(events[id] || {}).map(cb => cb(...variaveis))
  },
  $off: (id, key) => {
    events && events[id] && events[id][key] && delete events[id][key]
  }
}
Object.freeze(_EventBus)
export default _EventBus