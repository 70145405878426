import React from 'react'
import './404.scss'
import {Grid} from '@material-ui/core'
import NavLink from 'Components/NavLink'
import $color from 'Function/Color'
import $i18n from 'Function/i18n'

export default class extends React.Component {
    render() {
        return (
            <Grid id="notfound" container spacing={0} style={{margin: 0, padding: 0, height: '100%', width: '100%'}}
                  direction="column" justify="center"
                  alignItems="center" className="EurestoBodyConnection grad">
                <div className="notfound">
                    <div className="notfound-404">
                        <h3>Oops! Page not found</h3>
                        <h1><span style={{
                            marginLeft: -20, position: 'relative',
                            color: $color('primary')
                        }}>4</span>
                            <span style={{
                                postion: 'relative',
                                marginRight: 60
                            }}>
                        <i className={'ei-logo_BE EurestoSecondary--text'} style={
                            {
                                fontSize: 139,
                                zIndex: 2,
                                fontWeight: 12,
                                top: 117,
                                left: 220,
                                position: 'absolute',
                            }
                        }/>
                        <i className={'ei-logo_BE EurestoSecondary--text'} style={
                            {
                                fontSize: 139,
                                zIndex: 1,
                                fontWeight: 12,
                                top: 117,
                                left: 240,
                                position: 'absolute',
                            }
                        }/>
                <span className={'ei-logo_E EurestoPrimary--text'} style={
                    {
                        position: 'absolute',
                        fontSize: 183,
                        fontWeight: 12,
                        zIndex: 4,
                        left: 190,
                        top: 94,
                    }
                }/>
              </span>
                            <span style={{
                                marginLeft: 50, zIndex: 3, position: 'relative',
                                color: $color('primary')
                            }}>4</span></h1>
                    </div>
                    <h2>{$i18n.t(`commun:La page demandée n'a pas été trouvé`)}</h2>
                </div>
                <Grid container style={{margin: 0, padding: 0, position: 'absolute', top: '75%'}} spacing={0}
                      justify={'center'} alignItems={'center'}>
                    <NavLink exact className={'EurestoPrimary--text btn'}
                             style={{border: '1px solid black', padding: '0px 10px', fontSize: 15}} to='/'>
                        {$i18n.t(`commun:Revenir à la page d'accueil`)}</NavLink>
                </Grid>
            </Grid>
        )
    }
}
