const storage = {}
const _Storage = {
  setItem: (key, item) => {
    storage[key] = item
  },
  getItem: (key) => {
    return storage[key];
  }
}
Object.freeze(_Storage)
export default _Storage
