import { CircularProgress, Grid } from "@material-ui/core";
import Bus from "Class/Bus";
import User from "Class/User";
import Utils from 'Function/Utils';
import React, { useEffect, useMemo, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './ScrollBar.scss';

export default React.memo(props => {
        const ref = useRef()
        const guid = useMemo(() => Utils.uuidv4(), [])
        const isScrolling = useRef(false)
        const loading = useRef(props.infinityScroll && props.infinityScroll.showLoader && props.infinityScroll.isLoading && !props.infinityScroll.over)
        useEffect(() => {
            if (props.mainScrollBar)
                User.MainScrollbarRef.set(ref.current)
            return () => {
                if (props.mainScrollBar)
                    User.MainScrollbarRef.set(null)
            }
        }, [ref.current])


    useEffect(() => {
        if (props.infinityScroll)
                Bus.$on('search_' + props.search, () => {
                    loading.current = false
                }, guid)
                return () => Bus.$off('search_' + props.search, guid)
    }, [props.infinityScroll])

        return <PerfectScrollbar
            onYReachEnd={() => {
                if (isScrolling.current && props.infinityScroll && !props.infinityScroll.over && !props.infinityScroll.isLoading && !loading.current) {
                    loading.current = true
                    isScrolling.current = false
                    Bus.$emit('infinityScroll_search_' + props.search, true)
                }
            }}
            onScrollDown={() => isScrolling.current = true}
            ref={ref}
            component={props.component || 'div'}
            style={{
                height: props.height || props.maxHeight,
                maxHeight: props.maxHeight,
                ...(props.style || {})
            }} className={props.className || ''}>
            {
                props.children
            }
            {loading.current && !(props.infinityScroll || {}).over &&
            <Grid container spacing={0} style={{margin: 0, padding: 10}} justify={"center"} alignItems={"center"}
                  alignContent={"center"}>
                <CircularProgress size={20} thickness={4} className={'EurestoPrimary--text'}/>
            </Grid>}
        </PerfectScrollbar>
    }
)
