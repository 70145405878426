const i18next = require('i18next')

export default i18next
  .init({
    fallbackLng: 'fr',
    initImmediate: false,
    ns: ['commun', 'crm', 'gestion', 'reservation'],
    whitelist: ['fr', 'en'],
    defaultNS: 'commun',
    debug: false,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }
  }, (err, t) => {
  })
