import React from 'react'
import {SnackbarProvider} from "notistack";
import {makeStyles} from "@material-ui/core";

const styles = {
    success: {
        backgroundColor: 'white',
        color: '#03BD5B',
        border: '1px solid #03BD5B',
    },
    error: {
        backgroundColor: 'white',
        color: '#DC3545',
        border: '1px solid #DC3545',
    },
}


export default React.memo(props => {
    const classes = makeStyles(styles)
    return <SnackbarProvider
        maxSnack={3}
        preventDuplicate
        dense
        classes={{
            variantSuccess: classes.success,
            variantError: classes.error
        }}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}>
        {props.children}
    </SnackbarProvider>
})