import User from 'Class/User'
import axios from 'axios'

import qs from 'qs'

import project from 'Config/project'
import $storage from 'Class/Storage'

const Request = async (path, data, options = {}, headers = {crossdomain: true}) => {
  Object.keys(data).map(d => {
    if (data[d] === undefined || data[d] === 'null' || data[d] === 'NULL') {
      data[d] = null
    }
  })
  try {
    headers['Content-Type'] = options.contentType || 'application/json; charset=UTF-8'
    headers['Access-Control-Expose-Headers'] = 'Content-Type,Max-Length'

    if ($storage.getItem('token') && options.notSetToken !== true) {
      headers.Authorization = $storage.getItem('token')
    }
    let request
    if(headers['Content-Type'].includes('application/x-www-form-urlencoded')) {
      data = qs.stringify(data)
    }
    try {
      request = await axios({
        url: options && options.url || (project.api + (path)),
        method: options.method || 'POST',
        data: data,
        headers: headers,
        onUploadProgress: options.onUploadProgress,
        onDownloadProgress: options.onDownloadProgress,
        cancelToken: options.cancelToken
      })
    } catch (thrown) {
      if (axios.isCancel(thrown)) {
        return options.returnAnyway && ({
          code: -1
        }) || undefined
      } else {
        console.error(thrown)
        // handle error
      }
    }
    const result = request.data
    if (result && result.code === -112) {
      User.Disconnect.get()
    } else {
      if (options.showAlertError !== false && options.showOnlyOnError !== true) {
        User.NotificationSystem.callback({
          message: result.message,
          variant: result.code === 1 && 'success' || 'error',
        })
      }
      if (result && result.code >= 0 || options.returnAnyway === true) {
        return result
      }
    }
  } catch (error) {
    User.NotificationSystem.callback({
      message: 'Une erreur s\'est produite, lors de votre dernière action.',
      variant: 'error',
    })
    return {
      results: []
    }
  }
}

export default Request
