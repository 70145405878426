import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import App from '../../App.js';

export default React.memo(props => {
    const history = useHistory()

    useEffect(() => {
        const hash = window.location.hash && decodeURI(window.location.hash.replace('#', '')).replace(/\?.*/gi, '')
        let queryHash = window.location.search

        console.log(window.location)
        if (!queryHash) {
            queryHash = window.location.hash.replace(hash, '')
        }
        const query = {}
        queryHash.replace('?', '').split("&").map(test => {
            let [key, value] = test.split("=")
            if (value === "true" || value === "false") value = Boolean(value)
            if (key && value) query[key] = value
        })
        if (query && Object.keys(query).length > 0) {
            history.push({
                pathname: window.location.pathname,
                hash: hash,
                state: { ...query, loaded: true }
            })
        } else {
            history.push({
                pathname: window.location.pathname,
                hash: hash,
                state: { loaded: true }
            })
        }
    }, [])

    return (
        history && history.location && history.location.state && history.location.state.loaded && <App />
    );
})
