Number.isNaN = Number.isNaN || function (value) {
    return typeof value === 'number' && isNaN(value)
}

if (!Array.prototype.fill) {
    Object.defineProperty(Array.prototype, 'fill', {
        value: function (value) {

            // Steps 1-2.
            if (this == null) {
                throw new TypeError('this is null or not defined')
            }

            const O = Object(this)

            // Steps 3-5.
            const len = O.length >>> 0

            // Steps 6-7.
            const start = arguments[1]
            const relativeStart = start >> 0

            // Step 8.
            let k = relativeStart < 0 ?
                Math.max(len + relativeStart, 0) :
                Math.min(relativeStart, len)

            // Steps 9-10.
            const end = arguments[2]
            const relativeEnd = end === undefined ?
                len : end >> 0

            // Step 11.
            const final = relativeEnd < 0 ?
                Math.max(len + relativeEnd, 0) :
                Math.min(relativeEnd, len)

            // Step 12.
            while (k < final) {
                O[k] = value
                k++
            }

            // Step 13.
            return O
        }
    })
}

String.prototype.removeAccents = function () {
    return this
        .replace(/[áàãâä]/gi, 'a')
        .replace(/[éèêë]/gi, 'e')
        .replace(/[íìïî]/gi, 'i')
        .replace(/[óòöôõ]/gi, 'o')
        .replace(/[úùüû]/gi, 'u')
        .replace(/[ç]/gi, 'c')
        .replace(/[ñ]/gi, 'n')
        .replace(/[^a-zA-Z0-9]/g, ' ')
}

if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
        value: function (search, pos) {
            return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search
        }
    })
}

if (!String.prototype.insensitiveCaseAccents) {
    String.prototype.insensitiveCaseAccents = function () {
        return (this || '').toLowerCase().removeAccents().trim()
    }
}

if (!String.prototype.replaceAt) {
    String.prototype.replaceAt = function (index, character) {
        return this.substr(0, index) + character + this.substr(index + character.length)
    }
}

if (!Array.prototype.findIndex) {
    Object.defineProperty(Array.prototype, 'findIndex', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined')
            }

            const o = Object(this)

            // 2. Let len be ? ToLength(? Get(O, "length")).
            const len = o.length >>> 0

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function')
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            const thisArg = arguments[1]

            // 5. Let k be 0.
            let k = 0

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return k.
                const kValue = o[k]
                if (predicate.call(thisArg, kValue, k, o)) {
                    return k
                }
                // e. Increase k by 1.
                k++
            }

            // 7. Return -1.
            return -1
        },
        configurable: true,
        writable: true
    })
}

Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0])
}

if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (searchElement, fromIndex) {

            if (this == null) {
                throw new TypeError('"this" est nul ou non défini')
            }

            // 1. Soit o égal à ? Object(cette valeur).
            const o = Object(this)

            // 2. Soit len égal à ? Length(? Get(o, "length")).
            const len = o.length >>> 0

            // 3. Si len = 0, renvoyer "false".
            if (len === 0) {
                return false
            }

            // 4. Soit n = ? ToInteger(fromIndex).
            // Pour la cohérence du code, on gardera le nom anglais "fromIndex" pour la variable auparavant appelée "indiceDépart"
            //    (Si fromIndex n'est pas défini, cette étape produit la valeur 0.)
            const n = fromIndex | 0

            // 5. Si n ≥ 0,
            //  a. Alors k = n.
            // 6. Sinon, si n < 0,
            //  a. Alors k = len + n.
            //  b. Si k < 0, alors k = 0.
            let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

            function sameValueZero(x, y) {
                return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y))
            }

            // 7. Répéter tant que k < len
            while (k < len) {
                // a. Soit elementK le résultat de ? Get(O, ! ToString(k)).
                // b. Si SameValueZero(searchElement, elementK) est vrai, renvoyer "true".
                if (sameValueZero(o[k], searchElement)) {
                    return true
                }
                // c. Augmenter la valeur de k de 1.
                k++
            }

            // 8. Renvoyer "false"
            return false
        }
    })
}

if (!String.prototype.includes) {
    Object.defineProperty(String.prototype, 'includes', {
        value: function (search, start) {
            if (typeof start !== 'number') {
                start = 0
            }

            if (start + search.length > this.length) {
                return false
            } else {
                return this.indexOf(search, start) !== -1
            }
        }
    })
}

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined')
            }

            const o = Object(this)

            // 2. Let len be ? ToLength(? Get(O, "length")).
            const len = o.length >>> 0

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function')
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            const thisArg = arguments[1]

            // 5. Let k be 0.
            let k = 0

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                const kValue = o[k]
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue
                }
                // e. Increase k by 1.
                k++
            }

            // 7. Return undefined.
            return undefined
        },
        configurable: true,
        writable: true
    })
}

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (searchString, position) {
        const subjectString = this.toString()
        if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
            position = subjectString.length
        }
        position -= searchString.length
        const lastIndex = subjectString.lastIndexOf(searchString, position)
        return lastIndex !== -1 && lastIndex === position
    }
}
String.prototype.almostSameAs = function (s) {
    let longer = this;
    let shorter = s;
    if (this.length < s.length) {
        longer = s;
        shorter = this;
    }
    const longerLength = longer.length;
    if (longerLength === 0) {
        return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / longerLength;
}

function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    const costs = [];
    for (let i = 0; i <= s1.length; i++) {
        let lastValue = i;
        for (let j = 0; j <= s2.length; j++) {
            if (i === 0)
                costs[j] = j;
            else {
                if (j > 0) {
                    let newValue = costs[j - 1];
                    if (s1.charAt(i - 1) !== s2.charAt(j - 1))
                        newValue = Math.min(Math.min(newValue, lastValue),
                            costs[j]) + 1;
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
        }
        if (i > 0)
            costs[s2.length] = lastValue;
    }
    return costs[s2.length];
}

String.prototype.replaceAll = function (search, replacement) {
    const target = this
    return target.replace(new RegExp(search, 'g'), replacement)
}



