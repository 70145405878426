import React from 'react';
import ReactDOM from 'react-dom';
import History from 'Static/History';
import * as serviceWorker from 'serviceWorker';
import 'font-awesome/css/font-awesome.min.css';
import 'Style/main.scss'
import { polyfill } from 'es6-promise'
import 'moment/min/locales.min'
import 'event-source-polyfill'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import CssBaseline from '@material-ui/core/CssBaseline'
import 'Function/Prototype'
import Notification from 'Static/Components/Notification'
import { Route, Switch } from "react-router";
import { BrowserRouter as Router, } from "react-router-dom";

polyfill()


if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React);
}

ReactDOM.render(
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <CssBaseline />
        <Notification>
            <Router>
                <Switch>
                    <Route path="*" render={props => <History {...props} />} />
                </Switch>
            </Router>
        </Notification>
    </MuiPickersUtilsProvider >,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
