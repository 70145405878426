import User from 'Class/User'
import $i18n from 'Function/i18n'
import React from 'react'


let license = {
    inactive: 0,
    active: 1,
}

let services = {
    NONE: 0,
    CRM: 1,
    RESTAURANT: 2,
    GESTION: 3,
    FACTURATION: 4,
    MESSAGERIE: 5,
    GED: 6
}

let access = {
    EURESTO: 1,
    GROUPE: 3,
    ETABLISSEMENT: 4,
    USER_GROUPE: 5,
    USER_ETS: 6,
    CLIENT_RESA: 7,
    CLIENT: 8,
    PUBLIC: 9
}

let lang = {
    FR: 1,
    EN: 2
}

let typeAction = {
    CreationDossier: 1,
    ModificationDossier: 2,
    FermetureDossier: 3,

    AppelEnvoi: 10,
    AppelRecu: 11,

    EmailEnvoi: 20,
    EmailRecu: 21,

    Message: 30,
    Upload: 31,
    MessagePrive: 32,
    AssistanceLogicielEuresto: 34,

    SmsEnvoi: 40,
    SmsRecu: 41,

    Newsletter: 50
}

let text2img = {
    Assistance: 'user-md',
    Config: 'cogs',
    Prospect: 'suitcase',
    Devis: 'file',
    Archive: 'cogs',
    Administrative: 'cogs',
    1: 'volume-control-phone',
    2: 'phone',
    20: 'plus',
    23: 'bug',
    21: 'envelope',
    25: 'envelope',
    22: 'envelope-open',
    26: 'envelope',
    3: 'fax',
    28: 'bell',
    4: 'sticky-note',
    8: 'comment',
    24: 'comments',
    27: 'upload',
}

let relation = {
    type_relation_etablissement_client: 101,
    type_relation_etablissement_utilisateur: 102,
    type_relation_groupe_utilisateur: 103,
    type_relation_utilisateur_client: 201,
    type_relation_groupe_etablissement: 301,
    type_relation_distributeur_etablissement: 400 //TODO NON GÉRÉ
}

let broadcastAction = {
    add: 1,
    update: 2,
    delete: 3,
    replace: 4
}

let typeSaisie = {
    SaisieLibre: 1,
    SaisieLibreBool: 2,
    SaisieLibreLink: 31,
    SaisieLibreUpload: 35,
    SaisieLibreMultipleLink: 32,
    SaisieLibreMultiple: 12,
    SaisieLibreMultipleBool: 13,
    SaisieLibreMultipleUpload: 36,
    Bool: 3,
    ComboSimple: 4,
    ComboSimpleSaisieLibre: 5,
    ComboSimpleEntier: 6,
    ComboSimpleBool: 7,
    ComboSimpleUpload: 34,
    ComboMulti: 8,
    ComboMultiSaisieLibre: 9,
    ComboMultiEntier: 10,
    ComboMultiBool: 11,
    ComboMultiUpload: 33,
    TextArea: 14,
    Wysiwyg: 15,
    Newsletter: 40,
    Grapesjs: 41,
    Picture: 16,
    Profil: 17,
    InfoTiersDonnees: 18,
    InputTextBasic: 19,

    Relations: 21,
    Stars: 22,
    Date: 23,
    Adresses: 24,
    Mails: 25,
    Telephones: 26,
    Formulaire: 27,
    Upload: 28,
    PaysNationaliteLangue: 29,
    AutoComplete: 30,
}

let modules = {
    COMMUN: 'commun',
    CRM: 'crm',
    RESTAURANT: 'restaurant',
    GESTION: 'gestion',
    FACTURATION: 'facturation',
    FIDELITE: 'fidelite',
    MESSAGERIE: 'messagerie',
    SDD: 'sdd',
    INTERFACE: 'interface',
    PRIVEE: 'privee',
    CONFIGURATION: 'configuration',
    SELLSY: 'sellsy',
    PEDAGOGIE: 'pedagogie',
    ALLIANCE_RESEAUX: 'alliance_reseaux',
    NF525: 'nf525',
    OTA: 'ota',
    HOTEL: 'hotel'
}

let donnee = {
    document: 'document',
    type_mouvement: 'type_mouvement',
    rubrique: 'rubrique',
    famille: 'famille',
    unite: 'unite',
    allergene: 'allergene',
    info_tiers: 'info_tiers',
    categorie_unite: 'categorie_unite',
    info_tiers_donnees: 'info_tiers_donnees',
    type_produit: 'type_produit',
    taux_tva: 'taux_tva',
    type_tva: 'type_tva',
    type_unite: 'type_unite',
    information: 'information',

    versioning: 'versioning',
    plan_test: 'plan_test',

    produit: 'produit',
    planning: 'planning',
    table: 'table',
    table_categorie: 'table_categorie',
    carcasse: 'carcasse',
    motif_annulation: 'motif_annulation',
    motif_refus: 'motif_refus',

    onglet: 'onglet',
    type_saisie: 'type_saisie',

    relation: 'relation',
    groupe_utilisateur: 'groupe_utilisateur',
    dossier: 'dossier',

    facture: 'facture',
    pax: 'pax_categorie',
    point_vente: 'point_vente',
    remise: 'remise',
    type_marque: 'type_marque',
    marque_indice: 'marque_indice',
    marque_categorie: 'marque_categorie',
    mode_vente: 'mode_vente',
    type_point_vente: 'type_point_vente',
    offert: 'offert',
    lieu: 'lieu',
    regroupement_taxe: 'regroupement_taxe',
    mode_encaissement: 'mode_encaissement',

    classe: 'classe',
    formation: 'formation',
    eleve: 'eleve',
    exercice: 'exercice',

    activite: 'activite',
    civilite: 'civilite',
    module: 'module',
    fonction: 'fonction',
    langue: 'langue',
    mail_configuration: 'mail_configuration',
    pays: 'pays',
    profil: 'profil',
    region_tva: 'region_tva',
    sms_configuration: 'sms_configuration',
    type_archivage: 'type_archivage',

    groupe: 'groupe',
    etablissement: 'etablissement',
    utilisateur: 'utilisateur',
    client: 'client',

    classeur: 'classeur',
    type_action: 'type_action',
    notification: 'notification',
    action: 'action',
    qualite: 'qualite',

    configuration: 'configuration',

    upload: 'upload',
    type_client: 'type_client',
    type_media: 'type_media',
    enseigne: 'enseigne',

    filtre: 'filtre',

    type_messagerie: 'type_messagerie',
    message: 'message',

    service: 'service',
    interface: 'interface',
    flag: 'flag',
    reservation: 'reservation',

    option: 'option',
    template: 'template',
    template_type: 'template_type',
    sms: 'sms',
    messagerie: 'messagerie',

    sellsy: 'sellsy',
    guestonline: 'guestonline',
    insee: 'insee',

    alliance_reseaux: 'alliance_reseaux',
    abill: 'abill',
    ota: 'ota',
    prestataire: 'prestataire',
    commission: 'commission',
    billet: 'billet',
    categorie: 'categorie',
    exigence: 'exigence',

    tarif: 'tarif',
    origine: 'origine',
    segmentation: 'segmentation',
    fiche_stock: 'fiche_stock',
}

let dataType = {
    euresto: 5,
    groupe: 5,
    etablissement: 5,
    user: 4,
    commun: 5
}

let usefullObjectStatic = {
    [modules.COMMUN + '-' + donnee.pays]: {
        type: [{ id: 0, libelle: $i18n.t('commun:Inactif') }, { id: 1, libelle: $i18n.t('commun:Actif') }]
    },
    [modules.COMMUN + '-' + donnee.langue]: {
        type: [{ id: 0, libelle: $i18n.t('commun:Inactif') }, { id: 1, libelle: $i18n.t('commun:Actif') }]
    },
    [modules.GESTION + '-' + donnee.rubrique]: {
        type: [{ id: 1, libelle: $i18n.t('Rubrique produit') }, { id: 2, libelle: $i18n.t('Rubrique cave') }]
    },
    [modules.GESTION + '-' + donnee.famille]: {
        type: [
            { id: 1, libelle: $i18n.t('Produit en stock') },
            { id: 2, libelle: $i18n.t('Région/Sous-région vin') },
            { id: 3, libelle: $i18n.t('Fiche technique de base') }
        ]
    },
    [modules.CRM + '-' + donnee.civilite]: {
        type: [{ id: 1, libelle: $i18n.t('Civilité') }, { id: 2, libelle: $i18n.t('Forme Juridique') }]
    },
    [modules.CRM + '-' + donnee.info_tiers]: {
        entite: [
            { id: 1, libelle: $i18n.t('Personne') },
            { id: 2, libelle: $i18n.t('Entité') },
            {
                id: 4,
                libelle: $i18n.t('Établissement'),
                condition: (() => (User.TypeAccess.get(access.EURESTO)))
            },
            {
                id: 5,
                libelle: $i18n.t('Groupe'),
                condition: (() => (User.TypeAccess.get(access.EURESTO)))
            }
        ]
    },
    [modules.CRM + '-' + donnee.type_saisie]: {
        autorisation: [{ id: 1, libelle: $i18n.t('Tous'), default: true }, {
            id: 0, libelle: $i18n.t('Euresto'), condition: (() => (User.TypeAccess.get(access.EURESTO)))
        }]
    },
    [modules.CRM + '-' + donnee.onglet]: {
        type_contact: [
            {
                id: 1, libelle: $i18n.t('Client')
            },
            {
                id: 2,
                libelle: $i18n.t('Etablissement'),
                condition: (() => (User.TypeAccess.get(access.EURESTO)))
            },
            {
                id: 3,
                libelle: $i18n.t('Groupe'),
                condition: (() => (User.TypeAccess.get(access.EURESTO)))
            }
        ]
    },
    [modules.MESSAGERIE + '-' + donnee.configuration]: {
        connection: [
            {
                id: 1,
                libelle: (<div className={'EurestoPrimary'} style={{ borderRadius: 50, height: 15, width: 15 }} />)
            },
            {
                id: 0,
                libelle: (<div className={'EurestoError'} style={{ borderRadius: 50, height: 15, width: 15 }} />)
            },
            /*{
              id: 1,
              libelle: (<i className={'EurestoPrimary--text icon-07'} style={{fontSize: 10}}/>)
            },
            {
              id: 0,
              libelle: (<i className={'EurestoError--text icon-07'} style={{fontSize: 10}}/>)
            },*/
        ]
    },
    [modules.GESTION + '-' + donnee.type_mouvement]: {
        type: [
            { id: -1, libelle: $i18n.t(''), operation: undefined },
            { id: 10, libelle: $i18n.t('Entrée Diverse'), operation: 1 },
            { id: 20, libelle: $i18n.t('Sortie Diverse'), operation: 2 },
            { id: 30, libelle: $i18n.t('Inventaire'), operation: 3 },
            { id: 31, libelle: $i18n.t('Valorisation Inventaire'), operation: 3 },
            { id: 40, libelle: $i18n.t('Transfert'), operation: undefined, destination: true },
            { id: 50, libelle: $i18n.t('Commande'), operation: 1 },
            { id: 51, libelle: $i18n.t('Commande Archivée'), operation: 1 },
            { id: 55, libelle: $i18n.t('Livraison'), operation: 1 },
            { id: 56, libelle: $i18n.t('Livraison Archivée'), operation: 1 },
            { id: 60, libelle: $i18n.t('Préparation'), operation: 2 },
            { id: 70, libelle: $i18n.t('Vente'), operation: 2 },
            { id: 71, libelle: $i18n.t('Vente Annulation'), operation: 1 },
            { id: 100, libelle: $i18n.t('DBM Prévisionnel'), operation: 2 },
            { id: 110, libelle: $i18n.t('DBM Réel'), operation: undefined },
            { id: 111, libelle: $i18n.t('DBM Archivée'), operation: undefined },
            { id: 200, libelle: $i18n.t('Client Commande'), operation: 2 },
            { id: 201, libelle: $i18n.t('Client Livraison'), operation: 2 },
            { id: 301, libelle: $i18n.t('Mémo'), operation: undefined },
        ],
        operation: [
            { id: 1, libelle: $i18n.t('Plus (+)') },
            { id: 2, libelle: $i18n.t('Moins (-)') },
            { id: 3, libelle: $i18n.t('Init') },
        ]
    },
}

let dataObject = {
    [modules.PRIVEE + '-' + donnee.dossier]: {
        websocket: true,
        modifSearch: 'key_dossier',
        orderBy: ['actif desc', 'date_modification desc'],
        actif: true,
    },
    [modules.MESSAGERIE + '-' + donnee.configuration]: {
        websocket: true,
        orderBy: ['actif desc', 'libelle'],
        modifSearch: 'id',
        actif: true,
    },
    [modules.CONFIGURATION + '-' + donnee.groupe_utilisateur]: {
        websocket: true,
        orderBy: ['actif desc', 'libelle'],
        modifSearch: 'id',
        actif: true,
    },
    [modules.MESSAGERIE + '-' + donnee.message]: {
        websocket: true,
        orderBy: ['date_envoi desc'],
        modifSearch: 'id',
    },
    [modules.MESSAGERIE + '-' + donnee.type_messagerie]: {
        websocket: true,
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        modifSearch: 'id',
    },
    [modules.GESTION + '-' + donnee.produit]: {
        websocket: true,
        orderBy: ['actif desc', 'libelle', 'type'],
        actif: true,
        modifSearch: 'id',
    },
    [modules.GESTION + '-' + donnee.allergene]: {
        websocket: true,
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        modifSearch: 'id',
    },
    [modules.GESTION + '-' + donnee.rubrique]: {
        websocket: true,
        orderBy: ['actif desc', 'type', 'libelle'],
        actif: true,
        modifSearch: 'id',
    },
    [modules.GESTION + '-' + donnee.famille]: {
        orderBy: ['actif desc', 'type', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.GESTION + '-' + donnee.unite]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.GESTION + '-' + donnee.info_tiers_donnees]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.GESTION + '-' + donnee.categorie_unite]: {
        websocket: false,
        modifSearch: 'id',
    },
    [modules.GESTION + '-' + donnee.info_tiers]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.GESTION + '-' + donnee.onglet]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.GESTION + '-' + donnee.taux_tva]: {
        orderBy: ['libelle'],
        websocket: true,
        modifSearch: 'type_tva_id',
    },
    [modules.GESTION + '-' + donnee.type_tva]: {
        orderBy: ['libelle'],
        websocket: false,
        modifSearch: 'id',
    },
    [modules.COMMUN + '-' + donnee.interface]: {
        orderBy: ['libelle'],
        websocket: false,
        modifSearch: 'id',
    },
    [modules.GESTION + '-' + donnee.type_produit]: {
        orderBy: ['libelle'],
        websocket: false,
        modifSearch: 'id',
    },
    [modules.GESTION + '-' + donnee.type_unite]: {
        orderBy: ['libelle'],
        websocket: false,
        modifSearch: 'id',
    },
    [modules.CONFIGURATION + '-' + donnee.sms]: {
        orderBy: ['libelle'],
        websocket: false,
        modifSearch: 'id',
    },

    [modules.CRM + '-' + donnee.info_tiers]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.CRM + '-' + donnee.info_tiers_donnees]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.CRM + '-' + donnee.onglet]: {
        orderBy: ['actif desc', 'ordre'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.CRM + '-' + donnee.type_saisie]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.CRM + '-' + donnee.relation]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },


    [modules.NF525 + '-' + donnee.categorie]: {
        orderBy: ['code'],
        websocket: false,
        modifSearch: 'id',
    },
    [modules.NF525 + '-' + donnee.exigence]: {
        orderBy: ['code'],
        websocket: false,
        modifSearch: 'id',
    },
    [modules.NF525 + '-' + donnee.versioning]: {
        orderBy: ['version'],
        websocket: false,
        modifSearch: 'id',
    },
    [modules.NF525 + '-' + donnee.plan_test]: {
        orderBy: ['titre'],
        websocket: false,
        modifSearch: 'id',
    },

    [modules.COMMUN + '-' + donnee.activite]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.CONFIGURATION + '-' + donnee.etablissement]: {
        orderBy: ['libelle'],
        websocket: true,
        modifSearch: $i18n.t('commun:id'),
    },
    [modules.CONFIGURATION + '-' + donnee.utilisateur]: {
        orderBy: ['libelle'],
        websocket: true,
        modifSearch: $i18n.t('commun:id'),
    },
    [modules.CRM + '-' + donnee.enseigne]: {
        websocket: true,
        actif: true,
        orderBy: ['actif desc', 'libelle', 'id'],
        modifSearch: 'id',
    },
    [modules.CRM + '-' + donnee.type_client]: {
        websocket: true,
        actif: true,
        orderBy: ['actif desc', 'libelle', 'id'],
        modifSearch: 'id',
    },
    [modules.CRM + '-' + donnee.qualite]: {
        websocket: true,
        actif: true,
        orderBy: ['actif desc', 'libelle', 'id'],
        modifSearch: 'id',
    },
    [modules.COMMUN + '-' + donnee.civilite]: {
        websocket: true,
        actif: true,
        orderBy: ['actif desc', 'type', 'libelle'],
        modifSearch: 'id',
    },
    [modules.SDD + '-' + donnee.classeur]: {
        websocket: true,
        actif: true,
        orderBy: ['actif desc', 'libelle',],
        modifSearch: 'id',
    },
    [modules.SDD + '-' + donnee.flag]: {
        websocket: true,
        actif: true,
        orderBy: ['actif desc', 'libelle',],
        modifSearch: 'id',
    },
    [modules.COMMUN + '-' + donnee.modules]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.COMMUN + '-' + donnee.fonction]: {
        orderBy: ['actif desc', 'type', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.CONFIGURATION + '-' + donnee.template]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: $i18n.t('commun:id'),
    },
    [modules.COMMUN + '-' + donnee.option]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: $i18n.t('commun:id'),
    },
    [modules.CONFIGURATION + '-' + donnee.option]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: $i18n.t('commun:id'),
    },
    [modules.CONFIGURATION + '-' + donnee.langue]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: $i18n.t('commun:id'),
    },
    [modules.PRIVEE + '-' + donnee.filtre]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: $i18n.t('commun:id'),
    },
    [modules.CONFIGURATION + '-' + donnee.template_type]: {
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: $i18n.t('commun:id'),
    },
    [modules.COMMUN + '-' + donnee.langue]: {
        orderBy: ['actif desc', 'id'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.COMMUN + '-' + donnee.mail_configuration]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        modifSearch: 'id',
    },
    [modules.COMMUN + '-' + donnee.pays]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.CONFIGURATION + '-' + donnee.profil]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.GESTION + '-' + donnee.region_tva]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.CRM + '-' + donnee.type_archivage]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.RESTAURANT + '-' + donnee.motif_annulation]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.RESTAURANT + '-' + donnee.motif_refus]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.SDD + '-' + donnee.type_action]: {
        actif: true,
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    },
    [modules.COMMUN + '-' + donnee.type_media]: {
        actif: true,
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    },
    [modules.SDD + '-' + donnee.info_tiers]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.SDD + '-' + donnee.info_tiers_donnees]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },

    [modules.RESTAURANT + '-' + donnee.produit]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.RESTAURANT + '-' + donnee.service]: {
        orderBy: ['libelle'],
        websocket: true,
        modifSearch: 'id',
    },
    [modules.RESTAURANT + '-' + donnee.reservation]: {
        orderBy: ['libelle'],
        websocket: true,
        modifSearch: 'id',
    },

    [modules.RESTAURANT + '-' + donnee.table_categorie]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.RESTAURANT + '-' + donnee.table]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.RESTAURANT + '-' + donnee.carcasse]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },

    [modules.HOTEL + '-' + donnee.tarif]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.HOTEL + '-' + donnee.segmentation]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.HOTEL + '-' + donnee.fiche_stock]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.HOTEL + '-' + donnee.origine]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },

    [modules.PEDAGOGIE + '-' + donnee.classe]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.PEDAGOGIE + '-' + donnee.formation]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },
    [modules.PEDAGOGIE + '-' + donnee.exercice]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },


    [modules.ALLIANCE_RESEAUX + '-' + donnee.prestataire]: {
        orderBy: ['actif desc', 'libelle'],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },


    [modules.ALLIANCE_RESEAUX + '-' + donnee.billet]: {
        orderBy: ['actif desc', 'libelle_prestataire', `libelle`],
        actif: true,
        websocket: true,
        modifSearch: 'id',
    },


    [modules.ALLIANCE_RESEAUX + '-' + donnee.commission]: {
        actif: true,
        orderBy: ['de'],
        websocket: true,
        modifSearch: 'id',
    },


    [modules.INTERFACE + '-' + donnee.alliance_reseaux]: {
        //orderBy: ['api_dossier'],
        websocket: true,
        //modifSearch: 'id',
    },
    [modules.INTERFACE + '-' + donnee.abill]: {
        //orderBy: ['api_dossier'],
        websocket: true,
        //modifSearch: 'id',
    },


    [modules.GESTION + '-' + donnee.lieu]: {
        actif: true,
        orderBy: ['actif desc', 'principal desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    },

    [modules.GESTION + '-' + donnee.type_mouvement]: {
        actif: true,
        orderBy: ['actif desc', 'libelle', 'type'],
        websocket: true,
        modifSearch: 'id',
    },

    [modules.FACTURATION + '-' + donnee.mode_encaissement]: {
        actif: true,
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    }, [modules.FACTURATION + '-' + donnee.regroupement_taxe]: {
        actif: true,
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    }, [modules.FACTURATION + '-' + donnee.offert]: {
        actif: true,
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    }, [modules.CONFIGURATION + '-' + donnee.type_point_vente]: {
        actif: true,
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    }, [modules.FACTURATION + '-' + donnee.mode_vente]: {
        actif: true,
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    }, [modules.CONFIGURATION + '-' + donnee.marque_categorie]: {
        actif: true,
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    }, [modules.CONFIGURATION + '-' + donnee.marque_indice]: {
        actif: true,
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    }, [modules.CONFIGURATION + '-' + donnee.type_marque]: {
        actif: true,
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    }, [modules.FACTURATION + '-' + donnee.remise]: {
        actif: true,
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    }, [modules.CONFIGURATION + '-' + donnee.point_vente]: {
        actif: true,
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    }, [modules.FACTURATION + '-' + donnee.pax]: {
        actif: true,
        orderBy: ['actif desc', 'libelle'],
        websocket: true,
        modifSearch: 'id',
    },
}


const typeProduit = {
    ProduitALaVente: 1,
    FicheTechniqueALAVente: 2,
    ProduitEnStockeNonVendus: 3,
    ProduitCave: 4,
    FicheTechniqueDeBase: 5,
}

const themeUI = {
    1: 'default',
    2: 'orange-black',
    3: 'blue-black',
    //4: 'white',
    //5: 'white-orange',
    6: 'ultraviolet-moutarde',
    7: 'flat',
    //8: 'flat1',
    //9: 'flat2',
    //10: 'flat3',
    //11: 'flat4',
}

const methods = {
    GESTION: 'gestion',
    CRM: 'crm',
    RESTAURANT: 'restaurant',
    FIDELITE: 'fidelite',
    SDD: 'sdd',
    INTERFACE: 'interface',
    FACTURATION: 'facturation',
    HOTEL: 'hotel',
    SMS: 'sms'
}

const options = {
    [methods.GESTION]: {
        id: 1030,
        DBM: {
            id: 1031,
            method: 'dbm'
        },
        VIN: {
            id: 1032,
            method: 'vin'
        }
    },
    [methods.RESTAURANT]: {
        id: 1060,
        DONNEES_ANNEXES: {
            id: 151,
            method: 'donnees_annexes'
        },
        MENU: {
            id: 152,
            method: 'menu'
        },
        NOTIFICATION_SMS: {
            id: 153,
            method: 'notification_sms'
        },
        NOTIFICATION_EMAIL: {
            id: 154,
            method: 'notification_email'
        },
        NOTIFICATION_REFUS: {
            id: 155,
            method: 'notification_refus'
        },
        NOTIFICATION_DEMANDE: {
            id: 156,
            method: 'notification_demande'
        },
        NOTIFICATION_CONFIRMATION: {
            id: 157,
            method: 'notification_confirmation'
        },
        NOTIFICATION_ANNULATION: {
            id: 158,
            method: 'notification_annulation'
        },
        FAIT_MAISON: {
            id: 159,
            method: 'fait_maison'
        },
        PAIEMENT: {
            id: 160,
            method: 'restaurant_paiement'
        },
        PORTABLE_VERIFICATION: {
            id: 162,
            method: 'portable_verification'
        }
    },
    [methods.FACTURATION]: {
        id: 1010
    },
    [methods.FIDELITE]: {
        id: 200
    },
    [methods.PEDAGOGIE]: {
        id: 1040
    },
    [methods.HOTEL]: {
        id: 1020,
    },
    [methods.SMS]: {
        id: 950,
        INTERATIONAL: {
            id: 951,
            method: 'sms_international'
        },
        BLOQUE: {
            id: 952,
            method: 'sms_international'
        },
        PACK100: {
            id: 953,
            method: 'sms_pack_100'
        },
        PACK250: {
            id: 954,
            method: 'sms_pack_250'
        },
        PACK500: {
            id: 955,
            method: 'sms_pack_500'
        },
        PACK1000: {
            id: 956,
            method: 'sms_pack_1000'
        },
        PACK1500: {
            id: 957,
            method: 'sms_pack_1500'
        },
        PACK2000: {
            id: 958,
            method: 'sms_pack_2000'
        },
    },
    [methods.INTERFACE]: {
        id: 3000,
        ABILL: {
            id: 3040,
            method: 'abill'
        },
        GUESTONLINE: {
            id: 3061,
            method: 'guestonline'
        },
        ALLIANCE_RESEAUX: {
            id: 3011,
            method: 'alliance_reseaux'
        },
        ZEROSIX: {
            id: 3051,
            method: 'zerosix'
        },
        CENTRALPAY: {
            id: 3081,
            method: 'paiement'
        },
        VINISTORIA: {
            id: 3070,
            method: 'vinistoria'
        },
        SELLSY: {
            id: 3900,
            method: 'sellsy'
        },
        SESAME: {
            id: 3111,
            method: 'sesame'
        },
        OTA: {
            id: 3001,
            method: 'ota'
        },
    },
    [methods.OTA]: {
        id: 3001,
        method: 'ota',
        RESERVIT: {
            id: 3002,
            method: 'reservit'
        },
        DEDGE: {
            id: 3003,
            method: 'dedge'
        },
        NOVARESA: {
            id: 3004,
            method: 'novaresa'
        },
    },
    [methods.SYNCHRONISATION]: {
        id: 1500,
        MOBILE: {
            id: 1501,
            method: 'mobile'
        },
        CRM: {
            id: 1502,
            method: 'crm'
        },
        RESERVATION: {
            id: 1503,
            method: 'reservation'
        },
        PAIEMENT: {
            id: 1504,
            method: 'paiement'
        },
        GESTION: {
            id: 1505,
            method: 'gestion'
        },
    }
};

const typeDocumentStock = {
    INVENTAIRE: 1, //--- Saisie d'inventaire
    BON: 10, //--- Bon de mouvement divers
    COMMANDE: 50, //--- Commande fournisseur
    //COMMANDE_ARCHIVEE: 51, //--- Commande fournisseur archivée
    LIVRAISON: 55, //--- Livraison fournisseur
    //LIVRAISON_ARCHIVEE: 56, //--- Livraison fournisseur archivée
    //PREPARATION: 60, //--- Préparation de produits à la vente
    //VENTE_NOTE: 70, //--- Note en cours
    //VENTE_FACTURE: 80, //--- Facture
    //FT_MAJ: 90, //--- Maj des stocks des produits des fiches techniques
    //DBM: 100, //--- Maj des stocks des produits depuis DBM
    //DBM_ARCHIVE: 101, //--- Si annulation des sorties réeeles on conserve le document initial pou contrôles éventuels (pbl chez Ducasse)
    //Création DEVIS => CHÊNE BLEU
    //CLIENT_COMMANDE: 200, //--- Stock sorties prévisionnelles commande d'un client
    //CLIENT_LIVRAISON: 201, //--- Stock sorties validées livraison commande d'un client
    //MEMO: 301, //--- Mémorisation info produits (coût de revient)
}

const typeMouvementStock = {
    [typeDocumentStock.BON]: [10, 20, 40],
    [typeDocumentStock.INVENTAIRE]: [30, 31],
    [typeDocumentStock.COMMANDE]: [50],
    [typeDocumentStock.LIVRAISON]: [55],
    // [51/*typeDocumentStock.COMMANDE_ARCHIVEE*/]: [51],
    // [56/*typeDocumentStock.LIVRAISON_ARCHIVEE*/]: [56],
    // [60/*typeDocumentStock.PREPARATION*/]: [60],
    // [70/*typeDocumentStock.VENTE_NOTE*/]: [70, 71],
    // [100/*typeDocumentStock.DBM*/]: [100, 110],
    // [101/*typeDocumentStock.DBM_ARCHIVE*/]: [111],
    // [200/*typeDocumentStock.CLIENT_COMANDE*/]: [200],
    // [201/*typeDocumentStock.CLIENT_LIVRAISON*/]: [201],
    // [301/*typeDocumentStock.MEMO*/]: [301],
}

export default {
    services,
    access,
    lang,
    license,
    broadcastAction,
    typeSaisie,
    dataType,
    dataObject,
    typeAction,
    relation,
    usefullObjectStatic,
    text2img,
    typeProduit,
    themeUI,
    module: modules,
    donnee,
    methods,
    options,
    typeDocumentStock,
    typeMouvementStock,
}
