import React from 'react'
import {Grid} from "@material-ui/core";
import './index.scss'
import $i18n from 'Function/i18n'

export default React.memo(props => {

    return <Grid container
                 spacing={0}
                 style={{
                     margin: 0,
                     padding: 0,
                     height: '100%',
                     width: '100%',
                     zIndex: 1300,
                     position: 'absolute',
                     backgroundColor: '#99999988',
                 }} direction={'column'}
                 justify="center"
                 alignContent={"center"}
                 alignItems="center">
        <div style={{position: 'relative'}}>
          <span className={'ei-logo_E EurestoPrimary--text'}
                style={{
                    position: 'relative',
                    fontSize: 190,
                    zIndex: 3
                }}>
        </span>
            <span className={'MainBlockProgress'}
                  style={{
                      height: 145,
                      width: 150,
                      top: 24,
                      left: 50,
                      position: 'absolute',
                      border: '1px solid black',
                      zIndex: 1,
                      backgroundColor: 'transparent'
                  }}/>
            <div className={'MainBlockProgress2 EurestoSecondary'}
                 id={'MainBlockProgress2'}
                 style={{
                     height: 143,
                     width: props.type === 'page' && 148 || 0,
                     top: 25,
                     left: 51,
                     position: 'absolute',
                     zIndex: 2
                     //backgroundColor: 'transparent'
                 }}/>
        </div>
        <span className={'EurestoPrimary--text'} style={{fontSize: 15, position: 'relative'}}>
                {
                    props.libelle
                    || props.type === 'page' && $i18n.t('commun:Chargement de la page, veuillez patienter')
                    || $i18n.t('commun:Téléchargement de vos données en cours, veuillez patienter')
                }
            <span style={{fontSize: 15, position: 'absolute', bottom: 0}} className={'loading-ellipsis'}/>
            </span>
    </Grid>
})