import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Route, Switch } from "react-router";
import User from "Class/User";
import RouteData from 'Static/Data'
import Connection from 'Module/Page/Routes/Connection'
import NotFound from 'Static/Error/404'
import Download from 'Static/Download'
import $storage from 'Class/Storage'
import $store from 'Class/Store'
import Utils from 'Function/Utils'


const MiniatureContact = React.lazy(() => import('Module/Page/Routes/MiniatureContact'))
const SlackDeleteFile = React.lazy(() => import('Module/Page/Routes/SlackDeleteFile'))
const Unsubscribe = React.lazy(() => import('Module/Page/Routes/Unsubscribe'))
const ModificationMotDePasse = React.lazy(() => import('Module/Page/Routes/ModificationMotDePasse'))
const UploadGoogleStorage = React.lazy(() => import('Module/Page/Routes/UploadGoogleStorage'))

export default React.memo(props => {

    console.log('ROUTE', props)

    if (User.MainScrollbarRef.get() && User.MainScrollbarRef.get()._container) {
        User.MainScrollbarRef.get()._container.scrollTop = 0
    }

    const routes = useMemo(() =>
        !props.downloaded && !props.forgotPasswordCb && props.connectionAvailable && <Route path="*"><Download /></Route>
        || !props.forgotPasswordCb && props.connected && RouteData.filter(route => !route.autorisation || route.autorisation()).map(route => (route.children || [])
            .filter(child => (!child.autorisation || child.autorisation()) && child.component)
            .map(child => <Route key={route.path + child.path} exact path={route.path + child.path} render={props => <child.component {...props} {...child.props} />} />)), [props.connected, props.forgotPasswordCb, props.downloaded, props.connectionAvailable])

    const pages = useRef([<Route key={'PageMiniatureContact'} exact path='/page/contact/:id' render={props => <MiniatureContact {...props} />}></Route>,
    <Route key={'PageSlackDeleteFile'} exact path="/page/slack/file/delete" render={props => <SlackDeleteFile {...props} />}></Route>,
    <Route key={'PageUnsubscribe'} exact path="/page/unsubscribe/:key" render={props => <Unsubscribe {...props} />}></Route>,
    <Route key={'PageUploadGoogleStorage'} exact path="/page/etablissement/upload" render={props => <UploadGoogleStorage {...props} />}></Route>,
    <Route key={'PageModificationMotDePasse'} exact path="/page/modification/password/:key_generator" render={props => <ModificationMotDePasse {...props} />}></Route>,
    <Route key={'PageNotFound'} path="/page/*" component={NotFound} />
    ])

    return <React.Suspense fallback={<Download type={'page'} />}>
        <Switch>
            {
                pages.current
            }
            {
                routes
            }
            {
                props.connected && !props.forgotPasswordCb && <Route path="*" component={NotFound} /> ||
                <Route {...props} path="*" render={hprops => <Connection {...hprops} handleConnection={props.handleConnection} />}></Route>
            }
        </Switch>
    </React.Suspense>
})
