import React, {useEffect, useMemo, useState} from "react";
import {AppBar, Collapse, Grid, ListItem, ListItemIcon, ListItemText, Menu,} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import menuData from 'Static/Data'
import Logo from '../Components/Logo'
import $color from 'Function/Color'
import User from "Class/User";
import Bus from "Class/Bus";
import Utils from "Function/Utils";
import Color from 'color'
import $clone from 'Function/Clone'

export default React.memo(props => {
    const etablissementLibelle = () => {
        return User && User.Entite && User.Entite.get() && User.Entite.get().libelle || 'Euresto'
    }
    const [anchorActions, setAnchorActions] = useState(null)
    const [selectedItems, setSelectedItems] = useState([])
    const libelleEts = useMemo(etablissementLibelle, [])
    const [subComponent, setSubComponent] = useState(null)
    const [actions, setActions] = useState(null)
    const busGUID = useMemo(() => Utils.uuidv4(), [])
    const libelle = useMemo(() => {
        if (selectedItems.length === 0) {
            return libelleEts
        } else if (selectedItems.length === 1) {
            const mod = menuData.filter(mod => mod.showMenu !== false && (!mod.autorisation || mod.autorisation())).find(mod => mod.path === ('/' + selectedItems[0]))
            return mod.module
        } else {
            const mod = menuData.filter(mod => mod.showMenu !== false && (!mod.autorisation || mod.autorisation())).find(mod => mod.path === ('/' + selectedItems[0]))
            const men = mod.children.filter(mod => mod.showMenu !== false && (!mod.autorisation || mod.autorisation())).find(men => men.path === (!isNaN(parseInt(selectedItems[1])) ? '/:id' : (selectedItems[1] && ('/' + selectedItems[1]) || "")))
            const hash = window.location.hash && decodeURI(window.location.hash.replace('#', '')).replace(/\?.*/gi, '')

            if (hash) {
                return mod.module + '/' + men.label + '/' + hash
            } else {
                return mod.module + '/' + men.label
            }
        }
    }, [selectedItems]);

    useEffect(() => {
        let location = window.location.pathname

        if (location[0] === '/') {
            location = location.substring(1);
        }

        if (location !== "") {
            const items = location.split('/')
            if (items.length === 1) {
                items.push("")
            }
            setSelectedItems(items)
        }

        Bus.$on('footer_actions', d => {
            if(d && d.buttons && d.buttons.length > 0)
            setActions(d)
        }, busGUID)
        Bus.$on('menu_component', d => {
            setSubComponent(d)
            d ? setOpenMenu(true) : setOpenMenu(false)
        }, busGUID)
        return () => {
            Bus.$off('footer_actions', busGUID)
            Bus.$off('menu_component', busGUID)

            setSubComponent(null)
            setActions(null)
            setOpenMenu(false)
        }
    }, [])

    const [openMenu, setOpenMenu] = useState(false);

    const subMenu = useMemo(() => {
        const mod = menuData.filter(mod => mod.showMenu !== false && (!mod.autorisation || mod.autorisation())).find(mod => mod.path === ('/' + selectedItems[0]))
        return mod && mod.children.filter(men => men.showMenu !== false && (!men.autorisation || men.autorisation())).map(men => {
            return <ListItem key={'subMenu-footer-' + men.path} component={NavLink} exact to={mod.path + men.path}
                             style={{
                                 width: '100%',
                                 fontSize: 17,
                                 padding: '2.5px 2.5px 2.5px 2.5px',
                                 position: 'relative',
                                 margin: 0
                             }} button className={'MenuItemList EurestoText--text'}
                             onClick={() => {
                                 setSelectedItems([
                                     selectedItems[0],
                                     men.path && men.path.substring(1) || ''
                                 ])
                                 setOpenMenu(false)
                             }}>
                <ListItemIcon style={{minWidth: 'auto', color: 'inherit'}}>
                    <i className={men.icon}
                       style={{padding: '0px 5px 0px 2.5px', color: 'inherit'}}/>
                </ListItemIcon>
                <ListItemText style={{minWidth: 'auto', color: 'inherit'}}
                              primary={men.label}/>
            </ListItem>
        })
    }, [selectedItems])

    return <footer style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10000
    }}>
        <Collapse in={openMenu}>
            <Grid container className={`EurestoSecondary EurestoText--text`}
                  style={{
                      height: 38,
                      boxShadow: `0px -1px 5px 0px ${Color($color('primary')).darken(0.35)}`,
                      position: 'relative',
                      borderBottom: `2px solid ${Color($color('primary')).lighten(0.75)}`,
                  }}>
                <Grid item xs={2} style={{
                    borderRight: `1px solid ${Color($color('secondary')).darken(0.10)}`,
                    height: '100%',
                }}>
                    <Grid container direction={'column'} justify={'center'} alignItems={"center"}
                          style={{height: '100%', cursor: selectedItems.length > 0 && "pointer"}}
                          onClick={() => {
                              if (selectedItems.length > 0) {
                                  if (subComponent) {
                                      const s = $clone(selectedItems)
                                      s.pop()
                                      setSelectedItems(s)
                                  } else {
                                      setSelectedItems([])
                                  }
                              }
                          }}>
                        <i className={`ei-commun_chevron_left ${selectedItems.length === 0 && 'disabled'}`}/>
                    </Grid>
                </Grid>
                <Grid item xs={8} style={{
                    borderLeft: `1px solid ${Color($color('secondary')).lighten(0.10)}`,
                    borderRight: `1px solid ${Color($color('secondary')).darken(0.10)}`,
                    height: '100%',
                }}>
                    <Grid container direction={'column'} justify={'center'} alignItems={"center"} style={{height: '100%'}}>
                        <div style={{
                            whiteSpace: 'nowrap',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>{libelle}</div>
                    </Grid>
                </Grid>
                <Grid item xs={2} style={{
                    borderLeft: `1px solid ${Color($color('secondary')).lighten(0.10)}`,
                    height: '100%',
                }}>
                    <Grid container direction={'column'} justify={'center'} alignItems={"center"}
                          style={{height: '100%', cursor: "pointer"}} onClick={() => setOpenMenu(!openMenu)}>
                        <i className={`cursor-pointer ei-commun_chevron_${openMenu ? 'down' : 'up'}`}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction={'column'} justify={'center'} alignItems={"center"}
                  style={{maxHeight: 'calc(100vh - 76px)', overflow: 'auto'}}
                  className={`${'EurestoPrimary' || 'EurestoSecondary'} EurestoText--text`} wrap={"nowrap"}>
                {
                    selectedItems.length === 0 &&
                    menuData.filter(mod => mod.showMenu !== false && (!mod.autorisation || mod.autorisation())).map(mod => <ListItem key={mod.module} style={{
                        width: '100%',
                        maxHeight: '100%',
                        fontSize: 17,
                        padding: '2.5px 0px 2.5px 2.5px',
                        cursor: 'pointer',
                        position: 'relative',
                        margin: 0
                    }} className={'EurestoText'} onClick={() => {
                        setSelectedItems([mod.path.substring(1)])
                    }}>
                        <ListItemIcon style={{minWidth: 'auto'}}>
                            <i
                                style={{fontSize: 30, position: 'relative', zIndex: 1, paddingRight: 7}}
                                className={'ei-commun_cadre EurestoPrimary--text'}>
                                <i style={{fontSize: 20, position: 'absolute', top: 5, left: 5, zIndex: -1}}
                                   className={mod.icon + ' EurestoSecondary--text'}/>
                            </i>
                        </ListItemIcon>
                        <ListItemText className={'EurestoPrimary--text EurestoText'}
                                      style={{minWidth: 'auto', color: 'inherit'}}
                                      primary={mod.module}/>
                    </ListItem>)
                    || (selectedItems.length === 1 || !subComponent) && subMenu || subComponent
                }
            </Grid>
        </Collapse>
        <AppBar position="sticky"
                style={{
                    boxShadow: !openMenu && `0px -2px 0px 0px ${Color($color('primary')).darken(0.35)}`,
                    borderTop: `2px solid ${Color($color('primary')).lighten(0.35)}`,
                    height: 38,  position: 'relative'
                }}>
            <Grid container className={`${!openMenu && 'EurestoPrimary' || 'EurestoSecondary'} EurestoText--text`} style={{height: '100%', position: 'relative'}}>
                <Grid item style={{
                    borderRight: `1px solid ${Color($color(openMenu && 'secondary' || 'primary')).darken(openMenu && 0.10 || 0.35)}`,
                    width: 50, height: '100%'
                }}>
                    <Grid container direction={'column'} justify={'center'} alignItems={"center"}
                          alignContent={"center"}
                          style={{height: '100%', width: '100%'}}>
                        <Logo handleClick={() => setSelectedItems([])} beColor={openMenu && 'EurestoText'}
                              eColor={openMenu && 'EurestoPrimary'}/>
                    </Grid>
                </Grid>
                <Grid item xs onClick={() => setOpenMenu(!openMenu)} style={{
                    borderLeft: `1px solid ${Color($color(openMenu && 'secondary' || 'primary')).lighten(openMenu && 0.10 || 0.35)}`,
                    borderRight: `1px solid ${Color($color(openMenu && 'secondary' || 'primary')).darken(openMenu && 0.10 || 0.35)}`,
                    height: '100%'
                }}>
                    <Grid container direction={'column'} justify={'center'} alignItems={"center"}
                          style={{height: '100%'}}>
                        <div style={{
                            whiteSpace: 'nowrap',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>{!openMenu && libelle || libelleEts}</div>
                    </Grid>
                </Grid>
                <Grid item style={{
                    borderLeft: `1px solid ${Color($color(openMenu && 'secondary' || 'primary')).lighten(openMenu && 0.10 || 0.35)}`,
                    borderRight: `1px solid ${Color($color(openMenu && 'secondary' || 'primary')).darken(openMenu && 0.10 || 0.35)}`,
                    width: 25,
                    height: '100%'
                }}>
                    <Grid container direction={'column'} justify={'center'} alignItems={"center"}
                          style={{height: '100%', cursor: "pointer"}} onClick={() => setOpenMenu(!openMenu)}>
                        <i className={`cursor-pointer ei-commun_chevron_${openMenu ? 'down' : 'up'}`}/>
                    </Grid>
                </Grid>
                <Grid item style={{
                    borderLeft: `1px solid ${Color($color(openMenu && 'secondary' || 'primary')).lighten(openMenu && 0.10 || 0.35)}`,
                    width: 25,
                    height: '100%'
                }}>
                    <Grid container direction={'column'} justify={'center'} alignItems={"center"}
                          style={{height: '100%'}}>
                        <i className={`anchorActionsFooter ei-module_configuration ${!actions && 'disabled'}`}
                           aria-owns={Boolean(anchorActions) ? 'action-footer' : null}
                           aria-haspopup="true"
                           onClick={(event) => {
                               if (event &&
                                   (event.target.className.indexOf('anchorActionsFooter') !== -1
                                       || Utils.findparentClass(event.target.parentNode, 'anchorActionsFooter')))
                                   setAnchorActions(event.target)
                           }}
                           style={{fontSize: 18, borderRadius: 5, margin: '0px 2px', cursor: 'pointer'}}>
                            {
                                actions && actions.buttons && <Menu
                                    style={{zIndex: 30000}}
                                    id={'action-footer'}
                                    anchorEl={anchorActions || null}
                                    open={Boolean(anchorActions) || false}
                                    onClose={(event) => setAnchorActions(null)}>
                                    {
                                        actions.buttons.map(
                                            (button, i) => <ListItem key={'buttons-action-' + i} style={{
                                                width: '100%',
                                                fontSize: 17,
                                                padding: '2.5px 2.5px 2.5px 2.5px',
                                                position: 'relative',
                                                margin: 0,
                                                borderBottom: '1px solid lightgray'
                                            }}
                                                                     className={(button.disabled ? 'EurestoDisabled' : 'EurestoText--text ' + (button.className && button.className.replace('--text', '') || 'EurestoPrimary'))}
                                                                     onClick={() => {
                                                                         setAnchorActions(null)
                                                                         button.onChange(button.action)
                                                                     }}>
                                                <ListItemIcon style={{minWidth: 'auto', color: 'inherit'}}>
                                                    <i className={button.icon + ' ' + (button.disabled ? '' : 'EurestoText--text')}
                                                       style={{padding: '0px 5px 0px 2.5px', color: 'inherit'}}/>
                                                </ListItemIcon>
                                                <ListItemText style={{minWidth: 'auto', color: 'inherit'}}
                                                              primary={button.title}/>
                                            </ListItem>)
                                    }
                                </Menu>
                            }
                        </i>
                    </Grid>
                </Grid>
            </Grid>
        </AppBar>
    </footer>
})