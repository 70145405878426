import React, { useState, useEffect } from 'react'
import Request from 'Api/Request'
import './Connection.scss'

import Grid from '@material-ui/core/Grid'
import EurestoLogo from 'Style/font/euresto_01.png'
import { useHistory } from "react-router";

export default React.memo(props => {


  const history = useHistory()
  console.log(history)
  console.log(props)
  const [state, setState] = useState({
    heightPanel: 0,
    messageErreur: [],
    passwordForgot: props && props.location && props.location.state && props.location.state.passwordForgot,
    pagePasswordForgot: props && props.location && props.location.state && props.location.state.passwordForgot
  })

  const handleClick = async () => {
    const email = document.querySelector("#email")
    const password = document.querySelector("#password")
    if (email.value === '' || email.value === undefined) {
      setState(old => ({
        ...old,
        messageErreur: ['Veuillez saisir votre adresse email.']
      }))
      return
    }
    if (password.value === '' || password.value === undefined) {
      setState(old => ({
        ...old,
        messageErreur: ['Veuillez saisir votre mot de passe.']
      }))
      return
    }
    const result = await Request('configuration', {
      requete: 'user',
      action: 'login',
      email: email.value,
      password: password.value
    }, { returnAnyway: true })
    if (result.code === 1) {
      props.handleConnection(result.token)
    } else {
      setState(old => ({
        ...old,
        messageErreur: [result.message]
      }))
    }
  }

  useEffect(() => {
    const panel = document.querySelector("#ConnectionEurestoPanel")
    setState(old => ({
      ...old,
      heightPanel: panel && panel.style.height
    }))
  }, [])

  const deleteMessageErreur = _ => {
    setState(old => ({
      ...old,
      messageErreur: []
    }))
  }

  const sendEmail = async _ => {
    const email = document.querySelector("#email")
    const result = await Request('configuration', {
      requete: 'user',
      action: 'forgot_password',
      email: email.value,
    }, { returnAnyway: true })

    if (result && result.code === 1) {
      setState(old => ({
        ...old,
        emailSend: true
      }))
    } else {
      setState(old => ({
        ...old,
        messageErreur: [result.message]
      }))
    }
  }

  const panel = document.querySelector("#ConnectionEurestoPanel")
  const email = document.querySelector("#email")
  const { passwordForgot, emailSend, pagePasswordForgot } = state
  return (
    <Grid container spacing={0} style={{ margin: 0, padding: 0, height: '100vh' }} direction="column" justify="center"
      alignItems="center" className="EurestoBodyConnection grad">


      <Grid id={'ConnectionEurestoPanel'} item style={{
        margin: 0, padding: 0, position: 'relative',
        zIndex: 11,
      }} lg={2} md={4} sm={4} xs={6}
        onKeyPress={(e) => {
          if (e.charCode === 13) {
            !passwordForgot ? handleClick() : sendEmail()
          } else if (state.messageErreur.length > 0) deleteMessageErreur()
        }}>

        <Grid container style={{
          margin: 0,
          padding: 0,
          height: 'auto',
          zIndex: 11,
          position: 'absolute',
          bottom: panel && panel.innerHeight,
          fontSize: 20, width: '100%', textAlign: 'center'
        }} direction="column" justify="center" alignItems="stretch">
          {
            state.messageErreur.map((msg, i) => (
              <div className={'EurestoText--text EurestoError'} style={{
                width: '100%',
                padding: 5,
                fontSize: 12,
                marginBottom: 15,
                textAlign: 'justify',
                position: 'relative',
                borderRadius: 10
              }}>{msg}
              </div>
            ))
          }
        </Grid>
        {!emailSend && <Grid container style={{
          zIndex: 10,
          margin: 0,
          padding: 0,
          position: 'relative',
          paddingBottom: passwordForgot && 20 || 5,
          textAlign: 'center',
          background: 'rgb(0, 36, 49)',
          borderRadius: '10px',
        }} direction="column" justify="center" alignItems="stretch">
          {passwordForgot && !pagePasswordForgot && <a onClick={_ => setState(old => ({
            ...old,
            passwordForgot: false,
          }))} className={'cursor-pointer fa fa-2x fa-chevron ei-left EurestoText--text'}
            style={{ position: 'absolute', top: 10, left: 7 }} />}
          <img alt='Bandeau euresto' width="100%" style={{ marginTop: 0, minHeight: '1%' }} src={EurestoLogo} />
          {passwordForgot &&
            <span className={'EurestoText--text'}
              style={{ fontSize: 12, textAlign: 'middle', padding: 15, paddingBottom: 0, fontWeight: 'lighter' }}>Veuillez confirmer votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.</span>}
          <Grid container style={{
            margin: '10px 10px 0px 10px', padding: 0,
            borderRadius: '5px', position: 'relative', width: 'calc(100% - 20px)',
            border: '3px solid rgb(0, 159, 132)',
          }} spacing={0} justify="center"
            alignItems="center">

            <Grid container style={{
              margin: 0,
              padding: 0, width: '15%',
              backgroundColor: 'rgb(0, 36, 49)',
              borderRight: '3px solid rgb(0, 159, 132)',
              height: '40px',
            }} direction="column" justify="center" alignItems="stretch">
              <i className="ei-commun_action_mail fa-2x EurestoText--text" />
            </Grid>
            <input style={{
              backgroundColor: 'white',
              color: 'black',
              marginTop: 0,
              height: '40px',
              width: '85%',
              fontSize: '18px',
            }} id="email" type="email"
              autoFocus={true}
              placeholder=" Votre Email" />
          </Grid>
          {!passwordForgot && <Grid container style={{
            margin: '10px 10px 0px 10px', padding: 0,
            borderRadius: '5px', position: 'relative', width: 'calc(100% - 20px)',
            border: '3px solid rgb(0, 159, 132)',
          }} spacing={0} justify="center"
            alignItems="center">

            <Grid container style={{
              margin: 0,
              padding: 0,
              width: '15%',
              backgroundColor: 'rgb(0, 36, 49)',
              borderRight: '3px solid rgb(0, 159, 132)',
              height: '40px',
            }} direction="column" justify="center" alignItems="stretch">
              <i className="ei-commun_cadena_ferme fa-2x EurestoText--text" />
            </Grid>
            <input style={{
              backgroundColor: 'white',
              color: 'black',
              marginTop: 0,
              width: '85%',
              height: '40px',
              fontSize: '18px',
            }} id="password" type="password"
              placeholder=" Votre mot de passe" />
          </Grid>}
          <button style={{
            border: '3px solid rgb(0, 159, 132)',
            backgroundColor: 'white',
            width: 'calc(100% - 20px)',
            height: '40px',
            borderRadius: '5px',
            fontSize: '18px',
            margin: '10px 10px 0px 10px'
          }}
            onClick={!passwordForgot && handleClick || sendEmail}>{!passwordForgot && 'Se connecter' || 'Confirmer'}
          </button>
          {!passwordForgot && <span className={'EurestoText--text'} style={{ marginTop: 5, marginBottom: 2.5, fontWeight: 'bold', fontSize: 12, cursor: 'pointer' }}
            onClick={_ => setState(old => ({
              ...old,
              passwordForgot: true,
              messageErreur: []
            }))}>Mot de passe oublié ?</span>}
        </Grid>
          ||
          <Grid container style={{
            zIndex: 10,
            margin: 0,
            padding: 0,
            position: 'relative',
            paddingBottom: 5,
            textAlign: 'center',
            background: 'rgb(0, 36, 49)',
            borderRadius: '10px',
          }} direction="column" justify="center" alignItems="stretch">
            {passwordForgot && <a onClick={_ => setState(old => ({
              ...old,
              passwordForgot: true,
              emailSend: false
            }))} className={'cursor-pointer fa fa-2x fa-chevron ei-left EurestoText--text'}
              style={{ position: 'absolute', top: 10, left: 7 }} />}
            <img alt='Bandeau euresto' width="100%" style={{ marginTop: 0, minHeight: '1%' }} src={EurestoLogo} />
            <span style={{ margin: '5px 0px', fontWeight: 'bold', fontSize: 12 }}>Consultez votre boîte mail</span>
            <span className={'EurestoText--text'}
              style={{ fontSize: 12, textAlign: 'middle', padding: 15, paddingBottom: 0, fontWeight: 'lighter' }}>Nous venons d'envoyer un lien de réinitialisation du mot de passe à l'adresse e-mail de {email && email.value}</span>
            {!pagePasswordForgot && <span style={{ marginTop: 5, marginBottom: 2.5, fontWeight: 'bold', fontSize: 12 }}
              onClick={_ => setState(old => ({
                ...old,
                passwordForgot: false,
                emailSend: false,
                messageErreur: []
              }))}><a className={'EurestoText--text'} style={{ cursor: 'pointer' }}>Retourner à la page d'identification</a></span>}
          </Grid>}
      </Grid>

    </Grid>
  )
})
