import { createMuiTheme } from '@material-ui/core/styles/index'
import Constants from 'Constants'
import User from 'Class/User'
import Color from 'color'

const theme = {
    'default': {
        primary: 'rgb(0,44,66)',
        secondary: 'rgb(0,159,140)',
        text: 'rgb(255,255,255)',
        error: '#F05D5E',
        disabled: '#9C9C9C'
    },
    'orange-black': {
        primary: '#000000',
        secondary: '#FB9D00',
        text: 'rgb(255,255,255)',
        error: '#F05D5E',
        disabled: '#9C9C9C'
    },
    'blue-black': {
        primary: '#000000',
        secondary: '#2E9AFE',
        text: 'rgb(255,255,255)',
        error: '#F05D5E',
        disabled: '#9C9C9C'
    },
    'white': {
        primary: '#FFFFFF',
        secondary: '#2E9AFE',
        text: 'rgb(255,255,255)',
        error: '#F05D5E',
        disabled: '#9C9C9C'
    },
    'white-orange': {
        primary: '#f39c12',
        secondary: '#FFFFFF',
        text: 'rgb(255,255,255)',
        error: '#F05D5E',
        disabled: '#9C9C9C'
    },
    'ultraviolet-moutarde': {
        primary: '#321E5C',
        secondary: '#FFD902',
        text: 'rgb(255,255,255)',
        error: '#F05D5E',
        disabled: '#9C9C9C'
    },
    'flat': {
        primary: '#2980b9',
        secondary: '#2ecc71',
        text: 'rgb(255,255,255)',
        error: '#F05D5E',
        disabled: '#9C9C9C'
    },
}

export default () => {

    let themeUser = Constants.themeUI[User.User.get().theme_id]
    if (themeUser === undefined) {
        themeUser = 'default'
    }
    Object.keys(theme[themeUser]).map(key => {
        document.documentElement.style
            .setProperty('--' + key, theme[themeUser][key]);
    })
    return generateStyle(theme[themeUser])
}

const generateStyle = (color) => createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        text: {
            error: {
                main: color.error,
            },
        },
        primary: {
            main: color.primary,
        },
        secondary: {
            main: color.secondary,
        },
        error: {
            main: color.error,
        },
    },
    spacing: factor => [0, 4, 8, 16, 32, 64][factor],
    overrides: {
        MuiGrid: {
            container: {
                margin: 0,
                padding: 0
            },
            item: {
                margin: 0,
                padding: 0
            }
        },
        MuiButtonBase: {
            root: {
                padding: 1,
                minHeight: 'inherit',
                textTransform: 'none',
            }
        },
        MuiFab: {
            root: {
                padding: 1,
                minHeight: 'inherit',
                textTransform: 'none'
            },
            sizeSmall: {
                height: 24,
                width: 24,
                minHeight: 24,
                minWidth: 24,
                padding: 1
            }
        },
        MuiButton: {
            root: {
                padding: 1,
                minHeight: 'inherit',
                textTransform: 'none',
                minWidth: 35
            },
            text: {
                padding: '0px 6px'
            },
            sizeSmall: {
                height: 'auto',
                width: 'auto',
                minHeight: 24,
                minWidth: 24,
                padding: 1
            }
        },
        MuiInputAdornment: {
            positionEnd: {
                marginRight: 10
            },
        },
        MuiOutlinedInput: {
            root: {
                backgroundColor: 'white',
                border: 'none',
                boxShadow: '0 0 0 .5px ' + color.primary,
                borderRadius: 3,
                '&$disabled': {
                    color: Color("#000000").fade(.35).string()
                },
                '&$focused $notchedOutline': {
                    border: 'none',
                    borderTopLeftRadius: 0,
                },
                '&$focused': {
                    boxShadow: '0 0 0 1px ' + color.primary,
                    border: 'none',
                },
            },
            notchedOutline: {
                border: 'none',
                //backgroundColor: 'transparent',
                borderRadius: 3,
                '&$focused': {
                    borderRadius: 3,
                },
            },
            input: {
                padding: '12px 2.5px 0px 5px',
                zIndex: 1,
            },
            adornedEnd: {
                padding: '12px 10px 0px 0px',
            },
            adornedStart: {
                padding: '12px 0px 0px 10px',
            },
            inputMultiline: {
                padding: '12px 2.5px 0px 5px',
                zIndex: 1
            },
        },
        PrivateNotchedOutline: {
            root: {
                top: 0,
                borderTopLeftRadius: 0,
                border: 'none',
            },
            legend: {
                border: 'none',
            }
        },
        MuiFormLabel: {
            root: {
                color: color.text,
            }
        },
        MuiFormControlLabel: {
            label: {
                padding: "5px 0px 0px 0px"
            }
        },
        MuiInputLabel: {
            outlined: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '100%',
                lineHeight: 1.2,
                zIndex: 2,
                color: Color(color.primary).fade(.5).string(),
                transform: 'translate(5px, 16px) scale(.9)',
                '&$shrink': {
                    padding: '5px 5px .5px 5px',
                    backgroundColor: color.primary,
                    color: color.text + '!important',
                    transform: 'translate(-3px, 1px) scale(0.75)',
                    borderRadius: 3,
                    '&$focused': {
                        color: color.text + '!important',
                        boxShadow: '0 0 0 1px ' + color.primary,
                    },
                    '&$animated': {
                        color: color.text + '!important',
                    },
                },
            },
        },
        MuiTab: {
            textColorInherit: {
                opacity: 0.5,
            },
            root: {
                border: '1px solid ' + color.primary,
                maxWidth: '100%',
                minWidth: 'none',
                fontWeight: 600,
                maxHeight: 31,
                minHeight: 31,
                height: 31,
                backgroundColor: 'white',
                color: color.primary,
                padding: '0 10px',
            },
        },
        MuiTabs: {
            root: {
                maxHeight: 31,
                minHeight: 31,
                height: 31,
                background: 'transparent',
                color: color.primary,
            },
        },
        MuiIconButton: {
            root: {
                padding: 0,
                paddingLeft: 0,
                paddingRight: 0
            }
        },
        MuiList: {
            root: {
                padding: 0
            },
            padding: {
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        MuiMenuItem: {
            root: {
                minHeight: 'none'
            },
            gutters: {
                lineHeight: '.8em',
                padding: '5px 2.5px'
            }
        },
        MuiSelect: {
            select: {
                paddingRight: 0,
                textAlign: 'left'
            },
            icon: {
                width: 17,
                right: 5,
                color: 'rgba(0, 0, 0, 0.54)'
            }
        },
        MuiInput: {
            underline: {
                border: 'none',
            }
        },
        MuiSwitch: {
            root: {
                padding: 0,
                height: 22,
                width: 42
            },
            switchBase: {
                color: color.error,
                '&$checked': {
                    color: color.secondary,
                    transform: 'translateX(85%)'
                },
            },
        },
        PrivateSwitchBase: {
            root: {
                padding: 1
            }
        },
        MuiNotchedOutline: {
            '&$focused': {
                border: '2px solid ' + color.primary
            }
        },
        TabIndicator: {
            backgroundColor: color.secondary
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                margin: '0px 2.5px',
            },
            switchHeader: {
                padding: '5px 20px 5px 20px'
            },
            iconButton: {
                '&$MuiIconButton-disabled': {
                    color: color.error
                }
            }
        },
        MuiPickersDay: {
            day: {
                width: 36,
                height: 36,
                fontSize: 12,
                color: color.primary,
                borderRadius: 5,
                border: '1px solid ' + color.primary,
                margin: 2.5,
            },
            isSelected: {
                color: 'white',
                backgroundColor: color.primary,
            },
            isDisabled: {
                cursor: 'not-allowed',
                color: color.error,
                border: 'none',
            },
            current: {
                color: 'grey',
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                minHeight: 'none',
                padding: '10px 0px 40px 0px',
                justifyContent: 'flex-start'
            }
        },
        MuiTooltip: {
            tooltip: {
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: color.primary,
                backgroundColor: 'white',
                color: color.primary,
                lineHeight: '1.7em'
            }
        },
        /*MuiTooltip: {
          tooltip: {
            backgroundColor: color.primary,
            borderRadius: 5,
            fontSize: 15,
            border: '2px solid ' + color.secondary,
            padding: 0
          },
        },*/
        PrivateValueLabel: {
            circle: {
                width: 20,
                height: 20
            },
            offset: {
                letterSpacing: 'none',
                top: -15,
                left: 'none'
            }
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: '100%',
                background: 'transparent'
            },
            paperScrollPaper: {
                maxHeight: '100vh'
            }

        },
        MuiPaper: {
            elevation1: {
                backgroundColor: 'white',
                borderRadius: 5,
                boxShadow: `0 0 0 1px ${color.primary}, 1px 1px 3px lightgrey, -1px 1px 3px lightgrey`
            }
        },
        MuiSnackbarContent: {
            root: {
                color: 'grey',
                backgroundColor: 'white',
                borderRadius: 5,
            }
        },
        SnackbarItem: {
            'variantError-*': {
                backgroundColor: 'white',
                border: '1px solid #DC3545',
            },
            'variantSuccess-*': {
                backgroundColor: 'white',
                border: '1px solid #03BD5B',
            }
        },
        MuiTableCell: {
            root: {
                padding: '5px 4px 2px 4px',
                border: 'none',
            },
            body: {
                color: 'inherit',
                border: 'none'
            },
            head: {
                cursor: 'pointer',
                color: color.primary,
                fontWeight: 'bold',
                fontSize: '0.9rem',
                backgroundColor: 'white'
            },
        },
        MuiAppBar: {
            positionStatic: { transform: 'none' }
        },
        MuiTableRow: {
            root: {
                padding: "10px 0px 10px 0px",
                border: "1px solid black",
                margin: "10px 0px 10px 0px",
                '&:nth-of-type(odd)': {
                    backgroundColor: 'rgba(251,251,251,1)',
                    '&$selected': {
                        backgroundColor: color.secondary,
                        color: 'white'
                    },
                    '&$hover:hover': {
                        backgroundColor: color.primary,
                        color: 'white',
                    }
                },
                '&:nth-of-type(even)': {
                    backgroundColor: 'rgba(255,255,255,1)',
                    '&$selected': {
                        backgroundColor: color.secondary,
                        color: 'white'
                    },
                    '&$hover:hover': {
                        backgroundColor: color.primary,
                        color: 'white',
                    }
                },
                backgroundColor: 'white',
                '&$selected': {
                    backgroundColor: color.secondary,
                    color: 'white',
                },
                '&$hover:hover': {
                    backgroundColor: color.primary,
                    color: 'white',
                }
            },
            head: {
                padding: '10px 0px 10px 0px',
                margin: '10px 0px 10px 0px',
            },
        }
    }
})
