import React, {useMemo} from "react";
import Grid from "@material-ui/core/Grid/index";
import AppBar from "@material-ui/core/AppBar";
import {Toolbar} from "@material-ui/core";
import User from "Class/User";
import Avatar from 'Components/Avatar'
import {useHistory} from 'react-router-dom'

const styleFixed = {
    toolbar: {color: "black", height: 40, minHeight: 40,backgroundColor: 'white'},
    toolbarGridContainer: {margin: 0,padding: "5px 5px 0px 0px"},
}

export default React.memo(props => {
    const history = useHistory()

    const [onClick, style] = useMemo(() => {
        return [(() => {
            if(!User.User.isConnectedAs())
                history.push({
                pathname: '/compte',
                state: {}
            })
        }), { padding: 5, margin: 0, cursor: !User.User.isConnectedAs() && 'pointer' || 'not-allowed', display: 'inline' }]
    }, [User.User.isConnectedAs()])

    return <AppBar>
        <Toolbar style={styleFixed.toolbar}>
            <Grid container style={styleFixed.toolbarGridContainer} direction={'row'} justify={"flex-end"} alignItems={'center'}>
                <Grid item style={{cursor: 'default', fontSize: '80%', paddingRight: 5}}>
                    <Grid container direction={"column"} justify={"space-around"} alignItems={"center"}>
                        <span style={{lineHeight: 1, fontSize: 12, fontWeight: 350, marginBottom: -5}}>{User.User.get().prenom}</span>
                        <span style={{fontSize: 10, cursor: 'pointer', textDecoration: 'underline'}} onClick={User.Disconnect.get}>(Déconnexion)</span>
                    </Grid>
                </Grid>
                <div style={style} onClick={onClick}>
                    {User.User.get().media_lien &&
                    <Avatar src={User.User.get().media_lien} style={{ width: '30px', height: '30px' }}/>
                    ||
                    <i style={{ fontSize: 28 }} className={'ei-module_crm'}/>
                    }
                </div>
            </Grid>
        </Toolbar>
    </AppBar>
})